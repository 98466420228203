import React from "react";
import styled from "styled-components";
import Sidebar from "./Sidebar";
import bell from "../assets/bell.png";
import profileimage from "../assets/profileimage.png";
import "react-datepicker/dist/react-datepicker.css";
import { useNavigate } from "react-router-dom";

const Firstdiv = styled.p`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  float: left;
  margin-left: 25rem;
  margin-top: -28rem;
  
`;
const InputLabel = styled.label`
  font-family: "Inter";
  ${"" /* padding: 7px; */}
  font-style: normal;
  font-weight: 600;
  ${"" /* margin-top: 4rem; */}
  ${"" /* width: 14rem; */}
  text-align: left;
  font-size: 18px;
  color: #4a4a4a;
  display: flex;
  ::placeholder {
    font-family: "Source Sans Pro";
    font-size: 15px;
  }
  @media (min-width: 1024px) and (max-width: 1281px) {
    ${"" /* width: 90%; */}
    ${"" /* font-size: 2rem; */}
    ${"" /* margin-top: 4rem; */}
  }
`;
const Inputs = styled.input`
  /* border-bottom: 0; */
  ${"" /* padding-bottom: 5px; */}
  ${"" /* height:2rem; */}
  width: 86%;
  margin-top: 10px;
  height: 34px;
  border: none;
  margin-left: -3rem;

  ::placeholder {
    font-family: "Source Sans Pro";
    color: #848484;
    font-size: 15px;
  }
  @media (min-width: 1024px) and (max-width: 1281px) {
    width: 73%;
    height: 2rem;
    ::placeholder {
      font-family: "Source Sans Pro";
      color: #787878;
      font-size: 20px;
    }
  }
`;
const Input = styled.input`
  width: 25vmax;
  font-weight: 400;
  padding: 12px;

  background: #ffffff;
  border: 1px solid #e1e1e1;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.14);
  border-radius: 5px;
  ::placeholder {
    font-family: "Source Sans Pro";
    color: #787878;
    font-size: 15px;
  }

  @media (min-width: 1024px) and (max-width: 1281px) {
    ${"" /* margin-top: 2rem; */}
    ${"" /* width: 30rem; */}
    ${"" /* height: 2rem; */}
    ::placeholder {
      font-family: "Source Sans Pro";
      color: #787878;
      font-size: 25px;
    }
  }
`;
const InputDate = styled.input`
  width: 25vmax;
  font-weight: 400;
  padding: 12px;

  background: #ffffff;
  border: 1px solid #e1e1e1;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.14);
  border-radius: 5px;

  &::placeholder {
    color: rgba(0, 0, 0, 0.54);
  }

  @media (min-width: 1024px) and (max-width: 1281px) {
    margin-top: 2rem;
    width: 30rem;
    height: 2rem;
    ::placeholder {
      font-family: "Source Sans Pro";
      color: #787878;
      font-size: 25px;
    }
  }
`;
const Seconddiv = styled.p`
  float: right;
  ${"" /* border:1px solid red; */}
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-right: 4rem;
  margin-top: -33rem;

  @media (min-width: 1024px) and (max-width: 1281px) {
    margin-left: -4rem;
    margin-right: -16rem;
    margin-top: -37rem;
  }
`;
const Button = styled.button`
  background: #e07f50;
  border-radius: 40px;
  border: none;
  outline: none;
  margin-left:10vmax;
  font-family: "Source Sans Pro";
  font-size: 20px;
  font-weight: 600;
  cursor: pointer;
  padding: 0.5rem 1.5rem;
  color: #ffffff;
  margin-top: 1rem;
  @media (min-width: 1024px) and (max-width: 1281px) {
    ${"" /* margin-right: -3rem; */}
    ${"" /* width: 18%; */}
    font-size: 20px;
  }
`;

const TextArea = styled.textarea`
  width: 26vmax;
  background: #ffffff;
  border: 1px solid #e1e1e1;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.14);
  border-radius: 2px;
  ::placeholder {
    font-family: "Source Sans Pro";
    color: #787878;
    font-size: 17px;
    padding-left: 0.3rem;
  }
  @media (min-width: 1024px) and (max-width: 1281px) {
    font-size: 2px;
    height: 2rem;
    width: 30rem;
    margin-top: 2rem;
    ::placeholder {
      font-family: "Source Sans Pro";
      color: #787878;
      font-size: 25px;
    }
  }
`;
const SearchProfile = styled.div`
  border: none;
  ${"" /* margin-left: 20rem; */}
  margin-bottom: 3rem;
  margin-top: -11rem;
  width: 60vmax;
  height: 3rem;
  margin-left: 0rem;
  @media (min-width: 1024px) and (max-width: 1281px) {
    margin-left: 4rem;
  }
`;

const Bell = styled.img`
  float: right;
  display: flex;
  margin-right: 1.5rem;
  margin-top: -2rem;
  cursor: pointer;
  @media (min-width: 1024px) and (max-width: 1281px) {
    margin-right: 3rem;
  }
`;
const Profile = styled.img`
  float: right;
  cursor: pointer;
  margin-right: 2.2rem;
  margin-top: -2.2rem;
  @media (min-width: 1024px) and (max-width: 1281px) {
    margin-right: 7rem;
  }
`;
const Inputdiv = styled.input`
  height: 32px;
  margin-left: 0rem;
  width: 65rem;
  margin-top: 0.5rem;
  border: none;
  @media (min-width: 1024px) and (max-width: 1281px) {
    margin-left: -66px;
    width: 80%;
  }
`;
const Label = styled.label`
  width: 2rem;
  border: none;
  margin-left: -22rem;
  ${"" /* margin-top: 10px; */}
  margin-top: 2.8rem;
`;

const Countdiv = styled.div`
  margin-left: 26rem;
  margin-top: 0.5rem;
  @media (min-width: 1024px) and (max-width: 1281px) {
    margin-left: 23rem;
  }
`;
const Heading = styled.h1`
  margin-left: -3.5rem;
  @media (min-width: 1024px) and (max-width: 1281px) {
    margin-left: -3.5rem;
    width: 40%;
  }
`;
const Searchdiv = styled.div`
  display: flex;
  margin-left: -2rem;

  @media (min-width: 1024px) and (max-width: 1281px) {
    margin-left: -6rem;
  }
`;
const Maindiv = styled.div`
  display: flex;
  flex-direction: column;

  margin-left: -3rem;
  ${"" /* border:2px solid green; */}
`;
const Maindiv2 = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8rem;
  ${"" /* border:1px solid red; */}
  margin-top:4rem;
`;
const Label1 = styled.label`
  font-weight: 500;
  font-size: 22px;
  line-height: 27px;
  color: #000000;
`;
const Input1 = styled.input`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px;
  gap: 10px;
  width: 30vw;
  height: 59px;
  background: #ffffff;
  border: 1px solid #e1e1e1;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.14);
`;
const Coldiv1 = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

// const CustomInput = styled(InputBase)(({ theme }) => ({
//   '& input::placeholder': {
//     color: theme.palette.text.disabled,
//   },
// }));

const Certificate = () => {
  const navigate = useNavigate();
  const [searchInput, setSearchInput] = React.useState("");
  const [selectedDate, setSelectedDate] = React.useState(null);
  const [message, setMessage] = React.useState("");
  const [wordCount, setWordCount] = React.useState(0);
  const maxWords = 200;

  const handleMessageChange = (event) => {
    const newMessage = event.target.value;
    const wordCount = newMessage.trim().split(/\s+/).length;
    if (wordCount <= maxWords) {
      setMessage(newMessage);
      setWordCount(wordCount);
    }
  };
  const openLogin = () => {
    navigate("/profile");
  };
  const openNotification = () => {
    navigate("/notification");
  };

  return (
    <>
      <Sidebar />
      <Firstdiv>
        <SearchProfile>
          <Searchdiv style={{}}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              class="bi bi-search"
              viewBox="0 0 16 16"
              style={{
                marginTop: "22px",
                marginLeft: "36px",
                position: "relative",
                right: "16px",
              }}
            >
              <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
              <path d="M0 0h24v24H0z" fill="none" />
            </svg>
            <Inputs
              className="pl-8"
              // placeholder="Search here..."
              aria-label="Search through site content"
              type="text"
              value={searchInput}
              onChange={(e) => setSearchInput(e.target.value)}
            />
          </Searchdiv>
          <Label for="avatar">
            {/* <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-search" viewBox="0 0 16 16">
            <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z"/>
          </svg> */}
          </Label>

          <Profile src={profileimage} onClick={openLogin} />
          <Bell onClick={openNotification} src={bell} />
          <Heading>Certificate Form</Heading>
        </SearchProfile>

        <Maindiv>
          <Maindiv2>
            <Coldiv1>
              <Label1>Learner’s name</Label1>
              <Input1 required placeholder="Enter learner’s name" />
            </Coldiv1>
            <Coldiv1>
              <Label1>Level number</Label1>
              <Input1 required placeholder="Enter Level number" />
            </Coldiv1>
          </Maindiv2>

          <Maindiv2>
            <Coldiv1>
              <Label1>Course name</Label1>
              <Input1 required placeholder="Enter Course name" type="text" />
            </Coldiv1>
            <Coldiv1>
              <Label1>Description</Label1>
              <Input1 required placeholder="Enter Description" type="text" />
            </Coldiv1>
          </Maindiv2>
          <Maindiv2>
            <Coldiv1>
              <Label1>Date</Label1>
              <Input1 required placeholder="dd-mm-yyyy" type="date" />
            </Coldiv1>
          </Maindiv2>
        </Maindiv>

        <div className="flex justify-center items-center">
          <Button>Generate Certificate</Button>
        </div>
      </Firstdiv>
    </>
  );
};
export default Certificate;
