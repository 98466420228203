import {
  ListItemIcon,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import { style } from "@mui/system";
import React, { useState, useEffect } from "react";
import styled from "styled-components";
import searches from "../assets/search.png";
import Sidebar from "../Components/Sidebar";
import FormLoader from "../Components/FormLoader";
import profileimage from "../assets/profileimage.png";
import { useNavigate, useLocation } from "react-router-dom";
import { Edit, Delete } from "@mui/icons-material";
import { useFetch } from "../utils/customHook";
import { baseURL } from "../utils/api";

import axios from "axios";
const Maindiv = styled.div`
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  width: 73%;
  margin-left: 22rem;
  ${"" /* margin-top: -32rem; */}

  box-shadow: 6px 6px 16px rgba(0, 0, 0, 0.16);
  @media (min-width: 1024px) and (max-width: 1281px) {
    width: 67%;
    font-size: 1.5rem;
  }
`;
const Input = styled.input`
  width: 22rem;
  font-family: Inter;
  margin-left: 2rem;
  height: 36px;
  margin-top: 2rem;
  border-radius: 5px;
  margin-bottom: 2rem;
  box-shadow: 0px 6px 26px rgba(0, 0, 0, 0.12);

  ::placeholder {
    font-family: "Inter";
    color: #757575;
    font-size: 17px;
    width: 10rem;

    padding-left: 30px;
  }
  @media (min-width: 1024px) and (max-width: 1281px) {
  }
`;

const SearchProfile = styled.div`
  ${"" /* background-color:blue; */}
  ${"" /* margin-left:26rem; */}
      ${"" /* margin-bottom: 3rem; */}
      ${"" /* margin-top: -8rem; */}
      width: 73%;
  ${"" /* margin-top: 1rem; */}
  ${"" /* border: 1px solid red; */}
      margin-left: 22rem;
  margin-top: -55vmax;

  @media (min-width: 1024px) and (max-width: 1281px) {
    margin-left: 2rem;
  }
`;
const Topheading = styled.h1`
  margin-top: 2rem;
  margin-left: 22rem;
`;
const Bell = styled.img`
  float: right;
  display: flex;
  margin-top: -2.2rem;
  cursor: pointer;
  margin-right: 4rem;
  ${"" /* margin-left: 24rem; */}
  height: 33px;
  @media (min-width: 1024px) and (max-width: 1281px) {
    margin-right: -40rem;

    height: 36px;
  }
`;

const Profile = styled.img`
  float: right;
  margin-top: -2.2rem;
  margin-left: 42rem;
  height: 35px;
  cursor: pointer;
  @media (min-width: 1024px) and (max-width: 1281px) {
    ${"" /* margin-left: 30rem; */}
    height:36px;
    margin-right: -15rem;
  }
`;

const Label = styled.label`
  border: none;
  margin-top: 2.8rem;
  ${"" /* border:1px solid red; */}
  ${"" /* margin-top: 10px; */}
`;

const Inputdiv = styled.input`
  width: 90%;
  margin-top: 10px;
  height: 34px;
  ${"" /* border:1px solid black; */}
  ${"" /* margin-left: 23rem; */}
    border: none;
  @media (min-width: 1024px) and (max-width: 1281px) {
    margin-left: 21rem;
    width: 62%;
  }
`;

const Inputs = styled.input`
  width: 87%;
  margin-top: 10px;
  height: 34px;
  border: none;
  margin-left: 1rem;
  @media (min-width: 1024px) and (max-width: 1281px) {
    width: 90%;
    margin-top: 10px;
    height: 34px;
    margin-left: 1rem;
    border: none;
  }
`;
const Searchdiv = styled.div`
  margin-left: 5vmax;
  @media (min-width: 1024px) and (max-width: 1285px) {
    margin-left: 25vmax;
  }
`;

const Subcategories = () => {
  const [data, loading] = useFetch(`${baseURL}/learn/fetch/subcategory`);

  const [searchInput, setSearchInput] = React.useState("");
  const filteredSubcategories = data?.allCategories?.filter((u) => {
    return u?.title?.trim().toLowerCase().includes(searchInput);
  });
  const [categories, setCatrgoeis] = useState(data?.allCategories);

  useEffect(() => {
    if (searchInput.length < 2 || searchInput == "") {
      setCatrgoeis(data?.allCategories);
    } else {
      setCatrgoeis(filteredSubcategories);
    }
  }, [searchInput, data]);

  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = React.useState(null);

  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [page, setPage] = React.useState(0);
  // const classes = useStyles();
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const openLogin = () => {
    navigate("/profile");
  };
  const openNotification = () => {
    navigate("/notification");
  };

  const handleEdit = () => {
    // Implement your edit logic here
    handleClose();
  };

  const handleDelete = () => {
    // Implement your delete logic here
    handleClose();
  };
  const open2 = true;

  async function deleteUser(userId) {
    try {
      await axios.get(`${baseURL}/learn/delete/subcategory/${userId}`);
    } catch (error) {
      alert("Error");
      console.log(error);
    }
  }

  return (
    <>
      <Sidebar />
      <SearchProfile>
        {/* <Profile src={profileimage} onClick={openLogin} /> */}
        {/* <Bell onClick={openNotification} src={bell} /> */}
      </SearchProfile>
      <Topheading>Subcategories</Topheading>
      <Maindiv>
        <div style={{ display: "flex" }}>
          <Input
            className="pl-6"
            placeholder="Search here..."
            aria-label="Search through site content"
            type="text"
            value={searchInput}
            onChange={(e) => setSearchInput(e.target.value)}
          />
          <Label className="-ml-10" for="avatar">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              class="bi bi-search"
              viewBox="0 0 16 16"
              className="absolute ml-2"
            >
              <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
            </svg>
          </Label>
        </div>
        {!categories ? (
          <FormLoader open={true} />
        ) : (
          <div>
            <TableContainer>
              <Table aria-label="customized table">
                <TableHead>
                  <TableRow style={{ backgroundColor: "#F4F6F8" }}>
                    <TableCell style={{ fontWeight: "600" }} align="left">
                      Name
                    </TableCell>
                    <TableCell style={{ fontWeight: "600" }} align="left">
                      Main Category
                    </TableCell>
                    <TableCell style={{ fontWeight: "600" }} align="left">
                      Action
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {categories
                    ?.slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage
                    )
                    .map((i) => {
                      return (
                        <>
                          <TableRow
                            align="left"
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                          >
                            <TableCell
                              style={{ fontFamily: "Inter" }}
                              align="left"
                            >
                              {i.title}
                            </TableCell>
                            <TableCell align="left">{i.mainCategory}</TableCell>

                            <TableCell align="left">
                              <Delete
                                className="cursor-pointer"
                                onClick={() => deleteUser(i._id)}
                                sx={{ marginRight: 6, color: "red" }}
                              />
                            </TableCell>
                          </TableRow>
                        </>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25, { label: "All", value: -1 }]}
              count={data?.allCategories?.length}
              pageSize={4}
              rowsPerPage={rowsPerPage}
              page={page}
              SelectProps={{
                inputProps: {
                  "aria-label": "rows per page",
                },
                native: true,
              }}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </div>
        )}
      </Maindiv>
    </>
  );
};
export default Subcategories;
