import {
  ListItemIcon,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import { style } from "@mui/system";
import React, { useState, useEffect } from "react";
import DeleteAlert from "../Components/DeleteAlert";
import styled from "styled-components";
import searches from "../assets/search.png";
import Sidebar from "../Components/Sidebar";
import FormLoader from "../Components/FormLoader";
import profileimage from "../assets/profileimage.png";
import { useNavigate, useLocation } from "react-router-dom";
import { Edit, Delete } from "@mui/icons-material";
import { useFetch } from "../utils/customHook";
import { baseURL } from "../utils/api";

import axios from "axios";
const Maindiv = styled.div`
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  width: 73%;
  margin-left: 22rem;
  ${"" /* margin-top: -32rem; */}

  box-shadow: 6px 6px 16px rgba(0, 0, 0, 0.16);
  @media (min-width: 1024px) and (max-width: 1281px) {
    width: 67%;
    font-size: 1.5rem;
  }
`;
const Input = styled.input`
  width: 22rem;
  font-family: Inter;
  margin-left: 2rem;
  height: 36px;
  margin-top: 2rem;
  border-radius: 5px;
  margin-bottom: 2rem;
  box-shadow: 0px 6px 26px rgba(0, 0, 0, 0.12);

  ::placeholder {
    font-family: "Inter";
    color: #757575;
    font-size: 17px;
    width: 10rem;

    padding-left: 30px;
  }
  @media (min-width: 1024px) and (max-width: 1281px) {
  }
`;

const SearchProfile = styled.div`
  ${"" /* background-color:blue; */}
  ${"" /* margin-left:26rem; */}
        ${"" /* margin-bottom: 3rem; */}
        ${"" /* margin-top: -8rem; */}
        width: 73%;
  ${"" /* margin-top: 1rem; */}
  ${"" /* border: 1px solid red; */}
        margin-left: 22rem;
  margin-top: -55vmax;

  @media (min-width: 1024px) and (max-width: 1281px) {
    margin-left: 2rem;
  }
`;
const Topheading = styled.h1`
  margin-top: 2rem;
  margin-left: 22rem;
`;
const Bell = styled.img`
  float: right;
  display: flex;
  margin-top: -2.2rem;
  cursor: pointer;
  margin-right: 4rem;
  ${"" /* margin-left: 24rem; */}
  height: 33px;
  @media (min-width: 1024px) and (max-width: 1281px) {
    margin-right: -40rem;

    height: 36px;
  }
`;

const Profile = styled.img`
  float: right;
  margin-top: -2.2rem;
  margin-left: 42rem;
  height: 35px;
  cursor: pointer;
  @media (min-width: 1024px) and (max-width: 1281px) {
    ${"" /* margin-left: 30rem; */}
    height:36px;
    margin-right: -15rem;
  }
`;

const Label = styled.label`
  border: none;
  margin-top: 2.8rem;
  ${"" /* border:1px solid red; */}
  ${"" /* margin-top: 10px; */}
`;

const Inputdiv = styled.input`
  width: 90%;
  margin-top: 10px;
  height: 34px;
  ${"" /* border:1px solid black; */}
  ${"" /* margin-left: 23rem; */}
      border: none;
  @media (min-width: 1024px) and (max-width: 1281px) {
    margin-left: 21rem;
    width: 62%;
  }
`;

const Inputs = styled.input`
  width: 87%;
  margin-top: 10px;
  height: 34px;
  border: none;
  margin-left: 1rem;
  @media (min-width: 1024px) and (max-width: 1281px) {
    width: 90%;
    margin-top: 10px;
    height: 34px;
    margin-left: 1rem;
    border: none;
  }
`;
const Searchdiv = styled.div`
  margin-left: 5vmax;
  @media (min-width: 1024px) and (max-width: 1285px) {
    margin-left: 25vmax;
  }
`;

const AllContent = () => {
  const [data, loading] = useFetch(`${baseURL}/learn/fetch/subcategory`);

  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = React.useState(null);

  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [page, setPage] = React.useState(0);
  // const classes = useStyles();
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const openLogin = () => {
    navigate("/profile");
  };
  const openNotification = () => {
    navigate("/notification");
  };

  const handleEdit = () => {
    // Implement your edit logic here
    handleClose();
  };

  const handleDelete = () => {
    // Implement your delete logic here
    handleClose();
  };
  const open2 = true;

  async function deleteUser(userId) {
    try {
      await axios.get(`${baseURL}learn/delete/subcategory/${userId}`);
    } catch (error) {
      alert("Error");
      console.log(error);
    }
  }
  const [course, setCourse] = useState("Words");

  const handleChangeCourse = (e) => {
    setCourse(e.target.value);
  };
  return (
    <>
      <Sidebar />
      <SearchProfile>
        {/* <Profile src={profileimage} onClick={openLogin} /> */}
        {/* <Bell onClick={openNotification} src={bell} /> */}
      </SearchProfile>
      <Topheading>All Content</Topheading>
      <Maindiv>
        <div className="flex flex-col gap-3">
          <span>Select Course</span>
          <select
            value={course}
            onChange={handleChangeCourse}
            className="w-[20vw] border-none outline-none shadow-lg cursor-pointer"
          >
            <option>Words</option>
            <option>Vocabulary</option>
            <option>Videos</option>
            <option>Numbers</option>
          </select>
        </div>
        {course === "Words" ? (
          <WordTable type={course} />
        ) : course === "Vocabulary" ? (
          <VocabTable type={course} />
        ) : course === "Numbers" ? (
          <NumTable type={course} />
        ) : course === "Videos" ? (
          <VideoTable type={course} />
        ) : null}
      </Maindiv>
    </>
  );
};
export default AllContent;

const WordTable = ({ type }) => {
  const [data, isLoading, error] = useFetch(`${baseURL}/learn/fetch/words`);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [page, setPage] = useState(0);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const handleDeleteAll = async () => {
    try {
      await axios.get(`${baseURL}/learn/delete/content/`);
    } catch (error) {}
  };
  const [openSnakbar, setOpenSnackbar] = useState(false);
  const closeSnackbare = () => setOpenSnackbar(false);
  const handleDeleteSingle = async (id) => {
    try {
      await axios.get(`${baseURL}/learn/delete/content/${id}?type=${type}`);
      setOpenSnackbar(true);
    } catch (error) {}
  };

  return (
    <>
      <DeleteAlert
        open={openSnakbar}
        message="Deleted"
        handleClose={closeSnackbare}
      />
      <main className="mt-10 pb-10">
        {!data ? (
          <FormLoader open={true} />
        ) : (
          <TableContainer>
            <TableHead>
              <TableRow>
                <TableCell style={{ fontWeight: "600", width: "5vw" }}>
                  ID
                </TableCell>
                <TableCell style={{ fontWeight: "600", width: "5vw" }}>
                  Category
                </TableCell>
                <TableCell style={{ fontWeight: "600", width: "30vw" }}>
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.words
                ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((word, index) => {
                  return (
                    <TableRow key={word._id}>
                      <TableCell style={{ fontFamily: "Inter" }}>
                        {" "}
                        {index + 1}
                      </TableCell>
                      <TableCell style={{ fontFamily: "Inter" }}>
                        {" "}
                        {word.category}
                      </TableCell>
                      <TableCell style={{ fontFamily: "Inter" }}>
                        <div className="flex gap-4">
                          <button
                            onClick={() => handleDeleteSingle(word._id)}
                            className="bg-red-700 border-none outline-none cursor-pointer w-[7vw] p-2 rounded-md text-white"
                          >
                            Delete
                          </button>
                        </div>
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </TableContainer>
        )}

        <TablePagination
          rowsPerPage={rowsPerPage}
          count={data?.words?.length}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </main>
    </>
  );
};

const VocabTable = ({ type }) => {
  const [data, isLoading, error] = useFetch(`${baseURL}/learn/fetch/vocabs`);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [page, setPage] = useState(0);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const handleDeleteAll = async () => {
    try {
      await axios.get(`${baseURL}/play/delete/all/word/level1`);
    } catch (error) {}
  };
  const [openSnakbar, setOpenSnackbar] = useState(false);
  const closeSnackbare = () => setOpenSnackbar(false);
  const handleDeleteSingle = async (id) => {
    try {
      await axios.get(`${baseURL}/learn/delete/content/${id}?type=${type}`);
      setOpenSnackbar(true);
    } catch (error) {}
  };

  return (
    <>
      <DeleteAlert
        open={openSnakbar}
        message="Deleted"
        handleClose={closeSnackbare}
      />
      <main className="mt-10 pb-10">
        {!data ? (
          <FormLoader open={true} />
        ) : (
          <TableContainer>
            <TableHead>
              <TableRow>
                <TableCell style={{ fontWeight: "600", width: "5vw" }}>
                  ID
                </TableCell>
                <TableCell style={{ fontWeight: "600", width: "5vw" }}>
                  Category
                </TableCell>
                <TableCell style={{ fontWeight: "600", width: "30vw" }}>
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.vocabs
                ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((word, index) => {
                  return (
                    <TableRow key={word._id}>
                      <TableCell style={{ fontFamily: "Inter" }}>
                        {" "}
                        {index + 1}
                      </TableCell>
                      <TableCell style={{ fontFamily: "Inter" }}>
                        {" "}
                        {word.category}
                      </TableCell>
                      <TableCell style={{ fontFamily: "Inter" }}>
                        <div className="flex gap-4">
                          <button
                            onClick={() => handleDeleteSingle(word._id)}
                            className="bg-red-700 border-none outline-none cursor-pointer w-[7vw] p-2 rounded-md text-white"
                          >
                            Delete
                          </button>
                        </div>
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </TableContainer>
        )}

        <TablePagination
          rowsPerPage={rowsPerPage}
          count={data?.vocabs?.length}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </main>
    </>
  );
};

const NumTable = ({ type }) => {
  const [data, isLoading, error] = useFetch(`${baseURL}/learn/fetch/nums`);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [page, setPage] = useState(0);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const handleDeleteAll = async () => {
    try {
      await axios.get(`${baseURL}/play/delete/all/word/level1`);
    } catch (error) {}
  };
  const [openSnakbar, setOpenSnackbar] = useState(false);
  const closeSnackbare = () => setOpenSnackbar(false);
  const handleDeleteSingle = async (id) => {
    try {
      await axios.get(`${baseURL}/learn/delete/content/${id}?type=${type}`);
      setOpenSnackbar(true);
    } catch (error) {}
  };

  return (
    <>
      <DeleteAlert
        open={openSnakbar}
        message="Deleted"
        handleClose={closeSnackbare}
      />
      <main className="mt-10 pb-10">
        {!data ? (
          <FormLoader open={true} />
        ) : (
          <TableContainer>
            <TableHead>
              <TableRow>
                <TableCell style={{ fontWeight: "600", width: "5vw" }}>
                  ID
                </TableCell>
                <TableCell style={{ fontWeight: "600", width: "5vw" }}>
                  Category
                </TableCell>
                <TableCell style={{ fontWeight: "600", width: "30vw" }}>
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.nums
                ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((word, index) => {
                  return (
                    <TableRow key={word._id}>
                      <TableCell style={{ fontFamily: "Inter" }}>
                        {" "}
                        {index + 1}
                      </TableCell>
                      <TableCell style={{ fontFamily: "Inter" }}>
                        {" "}
                        {word.category}
                      </TableCell>
                      <TableCell style={{ fontFamily: "Inter" }}>
                        <div className="flex gap-4">
                          <button
                            onClick={() => handleDeleteSingle(word._id)}
                            className="bg-red-700 border-none outline-none cursor-pointer w-[7vw] p-2 rounded-md text-white"
                          >
                            Delete
                          </button>
                        </div>
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </TableContainer>
        )}

        <TablePagination
          rowsPerPage={rowsPerPage}
          count={data?.nums?.length}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </main>
    </>
  );
};

const VideoTable = ({ type }) => {
  const [data, isLoading, error] = useFetch(`${baseURL}/learn/fetch/videos`);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [page, setPage] = useState(0);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const handleDeleteAll = async () => {
    try {
      await axios.get(`${baseURL}/play/delete/all/word/level1`);
    } catch (error) {}
  };
  const [openSnakbar, setOpenSnackbar] = useState(false);
  const closeSnackbare = () => setOpenSnackbar(false);
  const handleDeleteSingle = async (id) => {
    try {
      await axios.get(`${baseURL}/learn/delete/content/${id}?type=${type}`);
      setOpenSnackbar(true);
    } catch (error) {
      console.log(error);
    }
  };
 
  return (
    <>
      <DeleteAlert
        open={openSnakbar}
        message="Deleted"
        handleClose={closeSnackbare}
      />
      <main className="mt-10 pb-10">
        {!data ? (
          <FormLoader open={true} />
        ) : (
          <TableContainer>
            <TableHead>
              <TableRow>
                <TableCell style={{ fontWeight: "600", width: "5vw" }}>
                  ID
                </TableCell>
                <TableCell style={{ fontWeight: "600", width: "5vw" }}>
                  Category
                </TableCell>
                <TableCell style={{ fontWeight: "600", width: "30vw" }}>
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.videos
                ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((word, index) => {
                  return (
                    <TableRow key={word._id}>
                      <TableCell style={{ fontFamily: "Inter" }}>
                        {" "}
                        {index + 1}
                      </TableCell>
                      <TableCell style={{ fontFamily: "Inter" }}>
                        {" "}
                        {word.category}
                      </TableCell>
                      <TableCell style={{ fontFamily: "Inter" }}>
                        <div className="flex gap-4">
                          <button
                            onClick={() => handleDeleteSingle(word._id)}
                            className="bg-red-700 border-none outline-none cursor-pointer w-[7vw] p-2 rounded-md text-white"
                          >
                            Delete
                          </button>
                        </div>
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </TableContainer>
        )}

        <TablePagination
          rowsPerPage={rowsPerPage}
          count={data?.videos?.length}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </main>
    </>
  );
};
