// this is a comman component for uploading words content.
// admin will choose categories and will upload content

import React, { useReducer } from "react";
import { useFetch } from "../../utils/customHook";
import styled from "styled-components";
import { baseURL } from "../../utils/api";
import { Accordion, AccordionSummary, IconButton, Stack } from "@mui/material";
import axios from "axios";
import FormLoader from "../../Components/FormLoader";
import { useForm } from "react-hook-form";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AccordionDetails from "@mui/material/AccordionDetails";

const Typography1 = styled.label`
  font-size: 1.5rem;
  font-weight: 500;
  @media (min-width: 1024px) and (max-width: 1281px) {
    font-size: 2rem;
  }
`;
const Maindiv = styled.div`
  display: flex;
  flex-direction: column;
  width: 75%;
  margin-left: 20rem;
  margin-top: -30rem;
  @media (min-width: 1024px) and (max-width: 1281px) {
    font-size: 10px;

    width: 70%;
  }
`;
const Label = styled.label`
  background: #9dc6fe;
  box-sizing: border-box;
  border: 1px solid #e1e1e1;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.14);
  border-radius: 2px;
  margin-left: 4rem;
  font-family: Source Sans Pro;
  color: #787878;
  font-size: 15px;
  color: #063c85;
  width: 13rem;
  display: inherit;
  font-weight: 600;
  text-align: center;
  padding: 9px;
  cursor: pointer;
`;
const Seconddiv = styled.div``;
const Input = styled.input`
  /* border-bottom: 0; */
  ${"" /* padding-bottom: 5px; */}
  ${"" /* height:2rem; */}
    width: 13vmax;
  height: 2rem;
  font-weight: 400;
  padding: 7px;
  background: #ffffff;
  border: 1px solid #e1e1e1;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.14);
  border-radius: 3px;
  ::placeholder {
    font-family: "Source Sans Pro";
    color: #848484;
    font-size: 15px;
  }
  @media (min-width: 1024px) and (max-width: 1281px) {
    width: 115%;
    height: 2rem;
    ::placeholder {
      font-family: "Source Sans Pro";
      color: #787878;
      font-size: 20px;
    }
  }
`;
const InputO1 = styled.input`
  width: 10vmax;
  height: 2rem;
  font-weight: 400;
  padding: 7px;
  background: #ffffff;
  border: 1px solid #e1e1e1;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.14);
  border-radius: 3px;
  ::placeholder {
    font-family: "Source Sans Pro";
    color: #848484;
    font-size: 15px;
  }
  @media (min-width: 1024px) and (max-width: 1281px) {
    width: 115%;
    height: 2rem;
    ::placeholder {
      font-family: "Source Sans Pro";
      color: #787878;
      font-size: 20px;
    }
  }
`;
const InputLabel = styled.label`
  padding: 0px;
  font-weight: 600;
  width: 11rem;
  ${"" /* margin:2rem; */}
  ${"" /* margin-left:10rem; */}
  text-align:left;
  font-size: 20px;
  color: #000000;
  display: flex;

  ::placeholder {
    font-family: "Source Sans Pro";
    font-size: 15px;
  }
  @media (min-width: 1024px) and (max-width: 1281px) {
    width: 50%;
    font-size: 2rem;
    font-weight: 500;
    margin-bottom: 2rem;
  }
`;
const Fulldiv = styled.div``;
const Firstdiv = styled.div``;

const Button = styled.button`
  background: #e07f50;
  border-radius: 40px;
  border: none;
  outline: none;
  font-family: "Source Sans Pro";
  cursor: pointer;
  margin-top: 1rem;
  /* identical to box height */
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  padding: 0.8rem 3rem;
  color: #ffffff;

  /* Inside auto layout */
  @media (min-width: 1024px) and (max-width: 1281px) {
    ${"" /* margin-left:1px; */}
    margin-left: 40rem;
  }
`;
const SearchProfile = styled.div`
  margin-left: 0rem;
  margin-bottom: 3rem;
  margin-top: -8rem;

  width: 99%;
  height: 3rem;
  @media (min-width: 1024px) and (max-width: 1281px) {
    margin-left: 4rem;
  }
`;
const Profile = styled.img`
  float: right;
  margin-right: 1rem;
  cursor: pointer;
  margin-top: -2.6rem;
  @media (min-width: 1024px) and (max-width: 1281px) {
    margin-right: 3rem;
  }
`;
const Bell = styled.img`
  float: right;
  cursor: pointer;
  display: flex;
  margin-right: 5.5rem;
  margin-top: -2.2rem;
`;
const Searchdiv = styled.div`
  display: flex;

  @media (min-width: 1024px) and (max-width: 1281px) {
    margin-left: -4rem;
    margin-right: 2rem;
  }
`;
const Inputs = styled.input`
  width: 80%;
  margin-top: 10px;
  height: 34px;
  margin-left: 1rem;
  border: none;

  ::placeholder {
    font-family: "Source Sans Pro";
    color: #848484;
    font-size: 15px;
  }
  @media (min-width: 1024px) and (max-width: 1281px) {
    width: 73%;
    height: 2rem;
    ::placeholder {
      font-family: "Source Sans Pro";
      color: #787878;
      font-size: 20px;
    }
  }
`;
const Heading = styled.h1`
  margin-left: 2rem;
  @media (min-width: 1024px) and (max-width: 1281px) {
    margin-left: -1rem;
    width: 30%;
    font-size: 2rem;
  }
`;
const InputLabel1 = styled.label`
  padding: 0px;
  font-weight: 600;
  width: 11rem;
  ${"" /* margin:2rem; */}
  ${"" /* margin-left:10rem; */}
  text-align:left;
  font-size: 20px;
  color: #000000;
  display: flex;
  margin-bottom: 1rem;
  ::placeholder {
    font-family: "Source Sans Pro";
    font-size: 15px;
  }
  @media (min-width: 1024px) and (max-width: 1281px) {
    width: 50%;
    font-size: 2rem;
    font-weight: 500;
    margin-bottom: 2rem;
  }
`;

const InputLabel2 = styled.label`
  padding: 0px;
  font-weight: 600;
  width: 11rem;
  ${"" /* margin:2rem; */}
  ${"" /* margin-left:10rem; */}
  text-align:left;
  font-size: 20px;
  color: #000000;
  display: flex;
  margin-top: 2.5rem;
  margin-bottom: -1rem;
  ::placeholder {
    font-family: "Source Sans Pro";
    font-size: 15px;
  }
  @media (min-width: 1024px) and (max-width: 1281px) {
    width: 50%;
    font-size: 2rem;
    font-weight: 500;
    margin-bottom: 2rem;
  }
`;

const WordsFruitsUploadForm = () => {
  const [data, loading] = useFetch(
    `${baseURL}/learn/fetch/subcategory?mainCategory=Words`
  );

  const { handleSubmit, formState } = useForm();
  const { isSubmitting } = formState;

  function reducer(state, action) {
    if (action.type === "CHANGE_INPUT") {
      const { type, payload } = action;
      return {
        ...state,
        [payload.field]: payload.value,
      };
    } else if (action.type === "FILE_CHANGE") {
      const { type, payload } = action;
      return {
        ...state,
        [payload.field]: payload.value,
      };
    }
    return state;
  }

  const initialState = {
    tamilName: "",
    hindiName: "",
    englishName: "",
    teluguName: "",
    tamilSound: "",
    hindiSound: "",
    englishSound: "",
    teluguSound: "",
    image: "",
    text: "",
    category: "",
  };

  const [state, dispatch] = useReducer(reducer, initialState);

  const handleInputChanges = (e) => {
    const field = e.target.name;
    const value = e.target.value;
    dispatch({
      type: "CHANGE_INPUT",
      payload: {
        value,
        field,
      },
    });
  };

  function handleFileChanges(e) {
    const value = e.target.files[0];
    const field = e.target.name;
    dispatch({
      type: "FILE_CHANGE",
      payload: {
        value,
        field,
      },
    });
  }

  const submitForm = async (e) => {
    try {
      const formData = new FormData();

      formData.append("englishName", state.englishName);

      formData.append("image", state.image);

      formData.append("text", state.text);
      formData.append("category", state.category);
      const { data } = await axios.post(
        `${baseURL}/learn/add/word/fruitcontent`,

        formData,

        {
          headers: {
            "content-type": "multipart/form-data",
          },
        }
      );
      toast("Uploaded");
    } catch (error) {
      alert("wrong");
      console.log(error);
    }
  };
  const open = true;
  return (
    <>
      <form
        className="mt-10 "
        onSubmit={handleSubmit(submitForm)}
        // style={{ marginTop: "2rem", marginLeft: "2rem" }}
      >
        <Stack direction="row" alignItems="center" spacing={2}>
          <Firstdiv>
            <Label for="image">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                class="bi bi-cloud-arrow-up-fill"
                viewBox="0 0 20 10"
              >
                <path d="M8 2a5.53 5.53 0 0 0-3.594 1.342c-.766.66-1.321 1.52-1.464 2.383C1.266 6.095 0 7.555 0 9.318 0 11.366 1.708 13 3.781 13h8.906C14.502 13 16 11.57 16 9.773c0-1.636-1.242-2.969-2.834-3.194C12.923 3.999 10.69 2 8 2zm2.354 5.146a.5.5 0 0 1-.708.708L8.5 6.707V10.5a.5.5 0 0 1-1 0V6.707L6.354 7.854a.5.5 0 1 1-.708-.708l2-2a.5.5 0 0 1 .708 0l2 2z" />
              </svg>
              Upload image-1
            </Label>
            <div
              style={{
                marginTop: "1vh",
                display: "flex",
                justifyContent: "center",
                textAlign: "center",
              }}
            >
              <span>{state.image?.name}</span>
            </div>

            <input
              type="file"
              style={{ display: "none" }}
              id="image"
              name="image"
              onChange={handleFileChanges}
              accept="image/*"
            />
            <IconButton
              color="primary"
              aria-label="upload picture"
              component="label"
            >
              {/* <input hidden accept="image/*" type="file" /> */}
            </IconButton>
          </Firstdiv>
        </Stack>

        <div className="p-10">
          <div className="flex gap-4">
            <Fulldiv className="grid grid-cols-4 gap-20 ">
              <div className="flex flex-col gap-4 ">
                <InputLabel>Title</InputLabel>
                <Input
                  name="englishName"
                  value={state.englishName}
                  onChange={handleInputChanges}
                  required
                  placeholder="Enter title (English)"
                  type="text"
                />
              </div>
            </Fulldiv>
            <Fulldiv className="grid grid-cols-4 gap-20 ">
              <div className="flex flex-col gap-4">
                <InputLabel>Enter Sound text</InputLabel>
                <Input
                  name="text"
                  value={state.text}
                  onChange={handleInputChanges}
                  required
                  placeholder="Enter Text for Sound"
                  type="text"
                />
              </div>
            </Fulldiv>
            <Fulldiv className="grid grid-cols-4 gap-20 ">
              <div className="flex flex-col gap-4">
                <InputLabel>Category</InputLabel>
                <select
                  required
                  name="category"
                  value={state.category}
                  onChange={handleInputChanges}
                  id=""
                  className="w-[25vw] h-[10vh] rounded-md border-none outline-none"
                >
                  <option value="">Choose Sub Category</option>
                  {data?.names?.map((val, idx) => {
                    return <option key={idx}>{val.title}</option>;
                  })}
                </select>
              </div>
            </Fulldiv>
          </div>

          <center className="mt-5">
            <Button disabled={isSubmitting} type="submit">
              Upload
            </Button>
          </center>
          {isSubmitting && <FormLoader open={open} />}
          <ToastContainer
            position="top-center"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            success
            theme="dark"
          />
        </div>
      </form>
    </>
  );
};

export default WordsFruitsUploadForm;
