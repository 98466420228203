import React, { useEffect } from "react";
import styled from "styled-components";
import { useUserStore } from "../utils/store";
import bluegroup from "../assets/bluegroup.png";
import yellowgroup from "../assets/yellowgroup.png";
import redgroup from "../assets/redgroup.png";
import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { Box, Table } from "@mui/material";
import { useNavigate } from "react-router-dom";
import CourseTable from "./CourseTable";
import Sidebar from "./Sidebar";
import PlayContentTable from "./PlayContentTable";
import Navbar from "./Navbar";

const Maindiv = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 20rem;
  margin-top: -50vmax;

  @media (min-width: 1024px) and (max-width: 1285px) {
    font-size: 10px;
  }
`;
const Topheading = styled.h2`
  font-family: "Source Sans 3";
  font-style: normal;
  font-weight: 600;
  font-size: 34px;
  line-height: 48px;

  color: #000000;
  margin-left: 2rem;
  margin-top: 0rem;
  @media (min-width: 1024px) and (max-width: 1285px) {
    font-size: 2rem;
    margin-top: -2rem;
  }
`;
const CardContent1 = styled.div`
  background-color: #d1e9fc;
  border-radius: 10px;
  width: 20vmax;
  text-align: center;
  padding-bottom: 2vmax;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  @media (min-width: 1024px) and (max-width: 1285px) {
    width: 20vmax;
    font-size: 16px;
  }
`;
const CardContent2 = styled.div`
  border-radius: 10px;
  background-color: #fff7cd;
  width: 20vmax;
  text-align: center;
  padding-bottom: 2vmax;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  @media (min-width: 1024px) and (max-width: 1285px) {
    width: 20vmax;
    font-size: 16px;
  }
`;
const CardContent3 = styled.div`
  background-color: #ffe7d9;
  border-radius: 10px;
  width: 20vmax;
  text-align: center;
  padding-bottom: 2vmax;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  @media (min-width: 1024px) and (max-width: 1281px) {
   
  }
`;
const Card1 = styled.div`
  border-radius: 10px;
`;
const CardDiv = styled.div`
  display: flex;
  margin-top: 2rem;
  margin-left: 0.5vmax;
  padding: 5px;
  @media (min-width: 1024px) and (max-width: 1285px) {
    margin-left: 2vmax;
  }
`;

const Card2 = styled.div`
  margin-left: 3rem;
`;
const Card3 = styled.div`
  margin-left: 3rem;
`;

const CardImage = styled.img`
  ${"" /* margin-left:7rem; */}
  width: 3vmax;
  height: 3rem;
  margin-top: 1rem;
`;

const CardText = styled.p`
  /* padding-top: 3vmax; */
  color: #103996;
  font-size: 30px;
  font-weight: bold;
  text-align: center;
  /* width:60%; */
  font-family: "Source Sans Pro";
  @media (min-width: 1024px) and (max-width: 1285px) {
    width: 90%;
  }
`;
const CardText1 = styled.p`
  /* padding-top: 3vmax; */
  color: #b78103;
  font-size: 30px;
  font-weight: bold;
  text-align: center;
  /* width:60%; */
  font-family: "Source Sans Pro";
  @media (min-width: 1024px) and (max-width: 1285px) {
    width: 90%;
  }
`;
const CardText2 = styled.p`
  /* padding-top: 3vmax; */
  color: #b72136;

  font-size: 30px;
  font-weight: bold;
  text-align: center;
  /* width:60%; */
  font-family: "Source Sans Pro";
`;
const Graph = styled.img`
  margin-left: 2rem;
  width: 60rem;
  margin-top: 3rem;
  height: 23rem;
  background: #ffffff;
  box-shadow: 6px 6px 16px rgba(0, 0, 0, 0.16);
  @media (min-width: 1024px) and (max-width: 1285px) {
    width: 90%;
  }
`;
const Tables = styled.table`
  margin-top: 5rem;
  width: 95%;
  margin-left: 10px;
  box-shadow: 6px 6px 16px rgba(0, 0, 0, 0.16);
  @media (min-width: 1024px) and (max-width: 1285px) {
    width: 95%;
    margin-left: 2rem;
  }
`;
const SearchProfile = styled.div`
  margin-left: 2rem;
  margin-bottom: 3rem;

  margin-top: -10rem;
  width: 84%;
  @media (min-width: 1024px) and (max-width: 1285px) {
    margin-left: 4rem;
  }
`;
const User1 = styled.div`
  height: 1vmax;
  color: #103996;
  font-size: 19px;
  font-weight: bold;
  text-align: center;
  margin-top: -2rem;
  font-family: "Source Sans Pro";
  @media (min-width: 1024px) and (max-width: 1285px) {
    width: 90%;
  }
`;
const User2 = styled.div`
  height: 1vmax;
  color: #b78103;
  font-size: 19px;
  font-weight: bold;
  text-align: center;
  margin-top: -2rem;
  font-family: "Source Sans Pro";
  @media (min-width: 1024px) and (max-width: 1285px) {
    width: 90%;
  }
`;

const User3 = styled.div`
  height: 1vmax;
  color: #b72136;
  font-size: 19px;
  font-weight: bold;
  text-align: center;
  margin-top: -2rem;
  font-family: "Source Sans Pro";
  @media (min-width: 1024px) and (max-width: 1285px) {
    width: 90%;
  }
`;
const Bell = styled.img`
  float: right;
  display: flex;
  margin-right: -7rem;
  margin-top: -35px;
  cursor: pointer;
  @media (min-width: 1024px) and (max-width: 1285px) {
    margin-right: -6rem;
    height: 35px;
  }
`;
const Profile = styled.img`
  float: right;
  margin-right: -11rem;
  margin-top: -38px;
  cursor: pointer;
  @media (min-width: 1024px) and (max-width: 1285px) {
    margin-right: -10rem;
  }
`;
const Input = styled.input`
  width: 100%;
  margin-top: 10px;
  height: 34px;
  border: none;
  margin-left: 1rem;
  @media (min-width: 1024px) and (max-width: 1285px) {
    width: 103%;
    margin-top: 10px;
    height: 34px;
    margin-left: 1rem;
  }
`;
const Label = styled.label`
width: 2rem,
 border: none;
 margin-left: -22rem;
    margin-top: 10px;
     @media (min-width: 1024px) and (max-width: 1285px) { 

  }
`;

const Chartdiv = styled.div`
  display: flex;
  flex-direction: column;
  width: 95%;
  margin-top: 40px;
  gap: 2rem;
  margin-left: 1vmax;
  box-shadow: 6px 6px 16px rgba(0, 0, 0, 0.16);
  @media (min-width: 1024px) and (max-width: 1285px) {
    width: 92%;
    margin-left: 3vmax;
  }
`;

const CustomTable = styled(Table)`
  border: none;

  & th,
  & td {
    border-bottom: none;
  }
`;

const Chartimg = styled.img`
  height: 38vh;
`;

const UsermanageDashboard = () => {
  const navigate = useNavigate();

  //data for the chart
  const data = [
    {
      name: "Page A",
      uv: 4000,
      pv: 2400,
      amt: 2400,
    },
    {
      name: "Page B",
      uv: 3000,
      pv: 1398,
      amt: 2210,
    },
    {
      name: "Page C",
      uv: 2000,
      pv: 9800,
      amt: 2290,
    },
    {
      name: "Page D",
      uv: 2780,
      pv: 3908,
      amt: 2000,
    },
    {
      name: "Page E",
      uv: 1890,
      pv: 4800,
      amt: 2181,
    },
    {
      name: "Page F",
      uv: 2390,
      pv: 3800,
      amt: 2500,
    },
    {
      name: "Page G",
      uv: 3490,
      pv: 4300,
      amt: 2100,
    },
  ];
  //end of chart data

  const { usersLength, setUsersLength } = useUserStore((state) => state);

  useEffect(() => {
    setUsersLength();
  }, []);

  return (
    <>
      <Sidebar />

      <Maindiv>
        {/* <Navbar /> */}

        <Topheading>Hi, Welcome back</Topheading>
        <CardDiv>
          <Card1>
            <CardContent1>
              <center>
                <CardImage src={bluegroup} />
              </center>
              <CardText>{usersLength}</CardText>
              <User1>Total User</User1>
            </CardContent1>
          </Card1>

          <Card2>
            <CardContent2>
              <center>
                <CardImage src={yellowgroup} />
              </center>
              <CardText1>{usersLength}</CardText1>
              <User2>Active User</User2>
            </CardContent2>
          </Card2>

          <Card3>
            <CardContent3>
              <center>
                <CardImage src={redgroup} />
              </center>
              <CardText2>0</CardText2>
              <User3>Inactive User</User3>
            </CardContent3>
          </Card3>
        </CardDiv>

        {/* <Box>
          <Chartdiv style={{}}>
            <h2 style={{ marginLeft: "3rem" }}>Total Subscribers</h2>
            <ResponsiveContainer height={400}>
              <BarChart
                data={data}
                margin={{
                  top: 5,
                  right: 30,
                  left: 20,
                  bottom: 5,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Bar dataKey="pv" fill="#8884d8" />
                <Bar dataKey="uv" fill="#82ca9d" />
              </BarChart>
            </ResponsiveContainer>
          </Chartdiv>
        </Box> */}
        
      </Maindiv>
    </>
  );
};
export default UsermanageDashboard;
