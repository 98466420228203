import React, { useReducer } from "react";
import {
  Typography1,
  Label,
  Input,
  InputO1,
  InputLabel,
  Fulldiv,
  Firstdiv,
  Button,
} from "../../Components/DesignComponents";
import { baseURL } from "../../utils/api";
import { Accordion, AccordionSummary, IconButton, Stack } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import axios from "axios";
import { useForm } from "react-hook-form";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AccordionDetails from "@mui/material/AccordionDetails";
import FormLoader from "../../Components/FormLoader";

const NumLevel3 = () => {
  const { handleSubmit, formState } = useForm();
  const { isSubmitting } = formState;

  function reducer(state, action) {
    const { payload } = action;
    if (action.type === "INPUT_CHANGE") {
      return {
        ...state,
        [payload.field]: payload.value,
      };
    } else if (action.type === "FILE_CHANGE") {
      return {
        ...state,
        image: action.payload,
      };
    }
    return state;
  }
  const initialState = {
    hindiTitle: "",
    englishTitle: "",
    teluguTitle: "",
    tamilTitle: "",
    optionA: "",
    optionB: "",
    optionC: "",
    optionD: "",
    correctOption: "",
    image: "",
  };
  const [state, dispatch] = useReducer(reducer, initialState);

  const handleChangeInputs = (e) => {
    const field = e.target.name;
    const value = e.target.value;
    dispatch({
      type: "INPUT_CHANGE",
      payload: {
        field,
        value,
      },
    });
  };

  const handleFileChange = (e) => {
    dispatch({
      type: "FILE_CHANGE",
      payload: e.target.files[0],
    });
  };

  const submitForm = async () => {
    const formData = new FormData();
    formData.append("text", state.englishTitle);
    formData.append("optionA", state.optionA);
    formData.append("optionB", state.optionB);
    formData.append("optionC", state.optionC);
    formData.append("optionD", state.optionD);
    formData.append("correctOption", state.correctOption);
    try {
      const { data } = await axios.post(
        `${baseURL}/play/upload/num/level3`,
        formData,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      toast("Level added");
    } catch (error) {
      console.log(error);
      toast.error("Something went wrong");
    }
  };
  const open = true;
  return (
    <>
      <form onSubmit={handleSubmit(submitForm)}>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography1>Level-3</Typography1>
          </AccordionSummary>
          <Stack direction="row" alignItems="center" spacing={2}>
            <Firstdiv>
              <Label for="num_imagelevel3">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  class="bi bi-cloud-arrow-up-fill"
                  viewBox="0 0 20 10"
                >
                  <path d="M8 2a5.53 5.53 0 0 0-3.594 1.342c-.766.66-1.321 1.52-1.464 2.383C1.266 6.095 0 7.555 0 9.318 0 11.366 1.708 13 3.781 13h8.906C14.502 13 16 11.57 16 9.773c0-1.636-1.242-2.969-2.834-3.194C12.923 3.999 10.69 2 8 2zm2.354 5.146a.5.5 0 0 1-.708.708L8.5 6.707V10.5a.5.5 0 0 1-1 0V6.707L6.354 7.854a.5.5 0 1 1-.708-.708l2-2a.5.5 0 0 1 .708 0l2 2z" />
                </svg>
                Upload image-1
              </Label>
              <div
                style={{
                  marginTop: "1vh",
                  display: "flex",
                  justifyContent: "center",
                  textAlign: "center",
                }}
              >
                <span>{state.image?.name}</span>
              </div>
              <input
                type="file"
                style={{ display: "none" }}
                id="num_imagelevel3"
                name="image"
                onChange={handleFileChange}
                accept="image/*"
              />
              <IconButton
                color="primary"
                aria-label="upload picture"
                component="label"
              ></IconButton>
            </Firstdiv>
          </Stack>

          <Stack direction="row" alignItems="center" spacing={2}></Stack>
          <AccordionDetails>
            <AccordionDetails>
              <div className="grid grid-cols-4 gap-4 ">
                <div>
                  <InputLabel>Title</InputLabel>
                  <Input
                    name="englishTitle"
                    value={state.englishTitle}
                    onChange={handleChangeInputs}
                    required
                    placeholder="Enter title (English)"
                    type="text"
                  />
                </div>
              </div>

              <div className="grid grid-cols-4 mt-8">
                <div>
                  <InputLabel>Option-1</InputLabel>
                  <InputO1
                    name="optionA"
                    value={state.optionA}
                    onChange={handleChangeInputs}
                    required
                    placeholder="Option-1"
                    type="text"
                  />
                </div>

                <div>
                  <InputLabel>Option-2</InputLabel>
                  <InputO1
                    name="optionB"
                    value={state.optionB}
                    onChange={handleChangeInputs}
                    required
                    placeholder="Option-2"
                    type="text"
                  />
                </div>
                <div>
                  <InputLabel>Option-3</InputLabel>
                  <InputO1
                    name="optionC"
                    value={state.optionC}
                    onChange={handleChangeInputs}
                    required
                    placeholder="Option-3"
                    type="text"
                  />
                </div>
                <div>
                  <InputLabel>Option-4</InputLabel>
                  <InputO1
                    name="optionD"
                    value={state.optionD}
                    onChange={handleChangeInputs}
                    required
                    placeholder="Option-4"
                    type="text"
                  />
                </div>
              </div>

              <Fulldiv
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginTop: "2rem",
                }}
              >
                <Firstdiv>
                  <InputLabel>Correct Option</InputLabel>
                  <Input
                    name="correctOption"
                    value={state.correctOption}
                    onChange={handleChangeInputs}
                    required
                    placeholder="Type Answer"
                    type="text"
                  />
                </Firstdiv>
              </Fulldiv>
              <center>
                <Button disabled={isSubmitting}>Upload</Button>
              </center>
              {isSubmitting && <FormLoader open={open} />}
              <ToastContainer
                position="top-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                success
                theme="dark"
              />
            </AccordionDetails>
          </AccordionDetails>
        </Accordion>
      </form>
    </>
  );
};

export default NumLevel3;
