import React from "react";
import styled from "styled-components";
import frametwo from "../assets/frameTwo.png";
import forgot from "../assets/forgot.png";
import { useNavigate } from "react-router-dom";

const Maindiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 14vw;
`;

const Card = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  background: #ffffff;
  box-shadow: 10px 10px 34px rgba(0, 85, 159, 0.25);
  border-radius: 15px;
  align-items: center;
  padding: 3% 3%;
`;

const Forgot = styled.p`
  font-family: "Lora";
  font-style: normal;
  font-weight: 700;
  font-size: 25px;
  color: #000000;
  /* text-align: center; */
`;

const Worries = styled.p`
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: 700;
  font-size: 17px;
  color: #797979;
`;
const Emaildiv = styled.div`
  margin-top: 20px;

  display: flex;
  flex-direction: column;
  gap: 20px;
  /* background:red; */
  width: 100%;
`;
const Email = styled.p`
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  color: #000000;
`;
const Addres = styled.input`
  border: none;
  outline: none;
  padding-left: 1px;
  ::placeholder {
    font-family: "Source Sans Pro";
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
  }
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0% 30% 0% 0%;
  gap: 5vw;
  align-items: center;
  background-color: #9dc6fe;
  @media (min-width: 1024px) and (max-width: 1281px) {
    width: 70%;
    height: 100%;
  }
`;
const SignupImgDiv = styled.div``;

const SginupImg = styled.img`
  width: 44.5vw;
   height: 97.4vh;
`;

const Icon = styled.img`
  margin-left: -8rem;
  height: 68px;
  margin-top: 25rem;
  position: absolute;
  z-index: 1;
`;
const FomrmWrap = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1px;
  @media (min-width: 1024px) and (max-width: 1281px) {
  }
`;
const ToLearn = styled.p`
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  color: #124e9f;
  margin-top: -9rem;
  letter-spacing: 2px;
  @media (min-width: 1024px) and (max-width: 1281px) {
    font-size: 2rem;
    font-family: "Source Sans Pro";
  }
`;
const InputWrapper = styled.div`
  margin-top: 2vmax;
  display: flex;
  flex-direction: column;
  gap: 20px;
  @media (min-width: 1024px) and (max-width: 1281px) {
    font-size: 2rem;
    font-family: "Source Sans Pro";
  }
`;
const InputLabel = styled.label`
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  ::placeholder {
    font-family: "Source Sans Pro";
    font-size: 15px;
  }
  @media (min-width: 1024px) and (max-width: 1281px) {
    font-size: 2rem;
    font-family: "Source Sans Pro";
  }
`;

const Input = styled.input`
  border: none;
  border-bottom: 1px solid black;
  outline: none;
  /* border-bottom: 0; */
  padding-bottom: 5px;
  background: transparent;
  width: 30vmax;
  padding-top: 10px;
  font-weight: 400;
  ::placeholder {
    font-family: "Source Sans Pro";
    color: #787878;
    font-size: 17px;
  }
  @media (min-width: 1024px) and (max-width: 1281px) {
    font-size: 2rem;
    font-family: "Source Sans Pro";
    ::placeholder {
      font-family: "Source Sans Pro";
      color: #787878;
      font-size: 28px;
    }
  }
`;
const Button = styled.button`
  color: #ffffff;
  background: #e07f50;
  border-radius: 40px;
  width: 27vmax;
  border: none;
  outline: none;
  margin-left: 3rem;
  height: 3vmax;
  cursor: pointer;
  font-weight: bold;
  font-size: 20px;
  margin-top: 30px;
  &:focus {
    outline: 1px solid black;
  }
  @media (min-width: 1024px) and (max-width: 1281px) {
    font-size: 2rem;
    margin-top: 2rem;
    margin-bottom: 1rem;
    font-family: "Source Sans Pro";
  }
`;
const ForgotPassword = () => {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate("/step1");
  };
  const handleSubmit = (e) => {
    e.preventDefault();
  };
  return (
    <>
      <Wrapper>
        <SignupImgDiv>
          <SginupImg src={forgot} />
          <Icon src={frametwo} />
        </SignupImgDiv>
        <FomrmWrap onSubmit={handleSubmit}>
          <ToLearn>Enter phone number to reset your password</ToLearn>
          <InputWrapper>
            <InputLabel>Email</InputLabel>
            <Input
              required
              placeholder="Enter your email address"
              type="email"
            />
          </InputWrapper>
          <center>
            <Button type="submit" onClick={handleClick}>
              Send
            </Button>
          </center>
        </FomrmWrap>
      </Wrapper>
    </>
  );
};
export default ForgotPassword;
