import React, { useState } from "react";
import axios from "axios";
import styled from "styled-components";
import frametwo from "../assets/frameTwo.png";
import { baseURL } from "../utils/api";
import { Link, Navigate, useNavigate } from "react-router-dom";
import frameThree from "../assets/frameThree.png";
import { Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { BsEye, BsEyeSlash } from "react-icons/bs";

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0% 30% 0% 0%;
  gap: 5vw;
  background-color: #9dc6fe;
  /* background: red; */
  /* margin-top:30px; */
  align-items: center;
  overflow: hidden;
  /* position: fixed; */
`;
const SignupImgDiv = styled.div``;

const SginupImg = styled.img`
  /* height: 40vw; */
  width: 44.5vw;
  height: 97.4vh;
`;

const FomrmWrap = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1px;
`;
const Heading = styled.h3`
  font-family: "Lora";
  font-style: normal;
  font-weight: 500;
  font-size: 45px;
`;

const ToLearn = styled.p`
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  color: #124e9f;
  margin-top: 15px;
  letter-spacing: 2px;
`;

const InputWrapper = styled.div`
  margin-top: 4vmax;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;
const InputLabel = styled.label`
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  overflow: hidden;
`;

const Input = styled.input`
  border: none;
  border-bottom: 1px solid black;
  outline: none;
  /* border-bottom: 0; */
  padding-bottom: 5px;
  background: transparent;
  width: 30vmax;
  padding-top: 10px;
  font-weight: 400;
  ::placeholder {
    color: #787878;
    font-size: 17px;
  }
`;

const Button = styled.button`
  color: #ffffff;
  background: #e07f50;
  border-radius: 40px;
  width: 27vmax;
  border: none;
  outline: none;
  height: 3.5vmax;
  cursor: pointer;
  font-weight: bold;
  font-size: 20px;
  margin-top: 30px;
  &:focus {
    outline: 1px solid black;
  }
`;

const Askquestio = styled.div`
  display: flex;
  margin-top: 25px;
  justify-content: center;
  align-items: center;
`;
const Account = styled.p`
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
`;
const Signup = styled.p`
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  color: #006ec1;
  text-decoration: underline;
`;
const Icon = styled.img`
  margin-left: -8rem;
  height: 68px;
  margin-top: 25rem;
  position: absolute;
  z-index: 1;
`;
const Login = () => {
  const {
    register,
    handleSubmit,
    formState: { isSubmitting, errors },
  } = useForm();

  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
  const [showPassword, setShowPassword] = React.useState(false);

  const navigate = useNavigate();

  const submitForm = async () => {
    const form = new FormData();
    form.append("email", formData.email);
    form.append("password", formData.password);
    try {
      const { data } = await axios.post(`${baseURL}/admin/login/admin`, form, {
        headers: {
          "Content-Type": "application/json",
        },
      });

      localStorage.setItem("elearnadmin", JSON.stringify(data));
      navigate("/");
    } catch (error) {
      toast.error(error.message);
    }
  };

  return (
    <>
      <Wrapper style={{ opacity: isSubmitting ? 0.3 : 1 }}>
        <SignupImgDiv>
          <div>
            <SginupImg src={frameThree} />
            <Icon src={frametwo} />
          </div>
        </SignupImgDiv>
        <FomrmWrap onSubmit={handleSubmit(submitForm)}>
          {/* <Heading>Login</Heading> */}
          <ToLearn>To learn more about us</ToLearn>

          <InputWrapper>
            <InputLabel>Email</InputLabel>
            <Input
              name="email"
              required
              placeholder="Enter your email address"
              type="email"
              onChange={handleChange}
              value={formData.email}
            />
          </InputWrapper>
          <InputWrapper>
            <Form.Group controlId="formBasicPassword">
              <InputLabel>Password</InputLabel>
              <div style={{ display: "flex" }}>
                <Input
                  name="password"
                  required
                  placeholder="Enter password"
                  type={showPassword ? "text" : "password"}
                  value={formData.password}
                  onChange={handleChange}
                />
                <div
                  style={{ marginLeft: "-2rem" }}
                  className="password-toggle"
                  onClick={() => setShowPassword(!showPassword)}
                >
                  {showPassword ? <BsEyeSlash /> : <BsEye />}
                </div>
              </div>
            </Form.Group>
          </InputWrapper>
          {/* <Forgot  style={{ cursor: "pointer" }}>Forgot password?</Forgot> */}
          <center>
            <Button type="submit">Login</Button>
          </center>
          {isSubmitting && (
            <span style={{ fontWeight: "bold", marginLeft: "1vmax" }}>
              Loading...
            </span>
          )}
          <ToastContainer
            position="top-center"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            success
            theme="dark"
          />
        </FomrmWrap>
      </Wrapper>
    </>
  );
};

export default Login;
