import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Sidebar from "../Components/Sidebar";
import bell from "../assets/bell.png";
import profileimage from "../assets/profileimage.png";
import "react-datepicker/dist/react-datepicker.css";
import { useNavigate } from "react-router-dom";
import uploadimage from "../assets/uploadimage.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCamera } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useFetch } from "../utils/customHook";
import { useForm } from "react-hook-form";
import { baseURL } from "../utils/api";

const Firstdiv = styled.p`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  float: left;
  margin-left: 30vmax;
  margin-top: -28rem;
  @media (min-width: 1024px) and (max-width: 1281px) {
    width: 65%;
  }
`;
const SearchProfile = styled.div`
  margin-bottom: 3rem;
  margin-top: -11rem;
  width: 60vmax;
  height: 3rem;
  @media (min-width: 1024px) and (max-width: 1281px) {
    margin-left: 4rem;
  }
`;

const Bell = styled.img`
  float: right;
  display: flex;
  margin-right: 4.8rem;
  margin-top: -2.5rem;
  @media (min-width: 1024px) and (max-width: 1281px) {
    margin-right: 8rem;
  }
`;
const Profile = styled.img`
  float: right;
  margin-right: 1rem;
  margin-top: -2.8rem;
  @media (min-width: 1024px) and (max-width: 1281px) {
    margin-right: 4rem;
  }
`;

const Heading = styled.h1`
  margin-left: -3rem;
  @media (min-width: 1024px) and (max-width: 1281px) {
    margin-left: -3rem;
    font-size: 3rem;
  }
`;
const Input = styled.input`
  width: 28vmax;
  font-weight: 400;
  padding: 7px;
  height: 1.5rem;
  background: #ffffff;
  border: 1px solid #e1e1e1;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.14);
  border-radius: 2px;
  ::placeholder {
    font-family: "Source Sans Pro";
    color: #787878;
    font-size: 16px;
  }
  @media (min-width: 1024px) and (max-width: 1281px) {
    height: 2rem;
    width: 25vmax;

    ::placeholder {
      font-family: "Source Sans Pro";
      color: #787878;
      font-size: 18px;
    }
  }
`;
const Button = styled.button`
  background: #e07f50;
  border-radius: 40px;
  border: none;
  outline: none;
  font-family: "Source Sans Pro";
  font-size: 18px;
  font-weight: 600;
  cursor: pointer;
  padding: 0.5rem 1.5rem;
  color: #ffffff;
  margin-top: 3rem;
  @media (min-width: 1024px) and (max-width: 1281px) {
  }
`;

const Rowdiv = styled.div``;

const SecondRowdiv = styled.div`
  display: flex;
  margin-top: 2.5rem;
  margin-left: -3rem;
`;
const Img = styled.img`
  @media (min-width: 1024px) and (max-width: 1281px) {
    height: 12rem;
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
`;

const Searchdiv = styled.div`
  display: flex;
  margin-left: -5rem;
  margin-right: 9rem;
  border: none;
  @media (min-width: 1024px) and (max-width: 1281px) {
    margin-left: -6rem;
    border: 1px solid red;
    margin-right: 2rem;
  }
`;
const Inputs = styled.input`
  width: 90%;
  margin-top: 10px;
  height: 34px;
  margin-left: 1rem;
  border: none;
  ::placeholder {
    font-family: "Source Sans Pro";
    color: #848484;
    font-size: 15px;
  }
  @media (min-width: 1024px) and (max-width: 1281px) {
    width: 73%;
    height: 2rem;
    ::placeholder {
      font-family: "Source Sans Pro";
      color: #787878;
      font-size: 20px;
    }
  }
`;

const Photodiv = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-left: -3rem;
  margin-top: 1rem;
`;

const Profilepage = () => {
  const {
    register,
    handleSubmit,
    formState: { isSubmitting, errors },
  } = useForm();

  const [admin, setAdmin] = useState(null);
  const [adminId, setId] = useState("");
  useEffect(() => {
    const value = JSON.parse(localStorage.getItem("elearnadmin"));
    if (value) {
      setAdmin(value);
      setId(value._id);
    }
  }, []);
  const [data] = useFetch(`${baseURL}/admin/get/profiledata/${adminId}`);

  const navigate = useNavigate();

  const [image, _setImage] = useState(null);

  const [searchInput, setSearchInput] = useState("");

  const [apiData, setApiData] = useState({
    email: "",
    phone: "",
    phone: "",
    name: "",
  });

  const [isEditable, setIsEditable] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [profilepic, setProfile] = useState("");

  const openLogin = () => {
    navigate("/login");
  };
  const openNotification = () => {
    navigate("/notification");
  };

  const handleImageSelect = (event) => {
    setProfile(event.target.files[0]);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setApiData({ ...apiData, [name]: value });
  };

  const toggleEditMode = () => {
    setIsEditable(!isEditable);
  };
  const imageRef = React.useRef(null);
  const handleClick = () => {
    imageRef.current?.click();
  };

  const submitForm = async () => {
    const formData = new FormData();
    formData.append("name", apiData.name || admin?.name);
    formData.append("email", apiData.email || admin?.email);
    formData.append("phone", apiData.phone || admin?.phone);
    formData.append("profile", profilepic);
    try {
      const { data } = await axios.post(
        `${baseURL}/admin/upload/pic/${adminId}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      toast.success("Profile Updated");
    } catch (error) {
      toast.error("Something went wrong");
    }
  };
  if (!data) {
    return <h1>Loading...</h1>;
  }
  return (
    <>
      <Sidebar />
      <Firstdiv>
        <SearchProfile>
          <Searchdiv style={{}}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              class="bi bi-search"
              viewBox="0 0 16 16"
              style={{
                marginTop: "18px",
                marginLeft: "-1rem",
                position: "relative",
                right: "-37px",
              }}
            >
              <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
              <path d="M0 0h24v24H0z" fill="none" />
            </svg>
            <Inputs
              className="pl-8"
              // placeholder="Search here..."
              aria-label="Search through site content"
              type="text"
              value={searchInput}
              onChange={(e) => setSearchInput(e.target.value)}
            />
          </Searchdiv>
          <Profile src={profileimage} onClick={openLogin} />
          <Bell onClick={openNotification} src={bell} />
          <Heading>Profile</Heading>
        </SearchProfile>
        {/* {selectedImage && <img src={selectedImage} alt="Selected Image" />} */}
        {/* {previewImage && <img src={previewImage} alt="Selected image" width="100" height="100"   />} */}
        <form onSubmit={handleSubmit(submitForm)}>
          <Photodiv>
            <label htmlFor="image-upload-button">
              <Img
                src={data?.profilepic || uploadimage}
                alt="Upload Image"
                className="w-44 h-44 rounded-full object-cover"
              />
              <FontAwesomeIcon
                style={{
                  marginLeft: "-3rem",
                  marginBottom: "2.5rem",
                  color: " #124E9F",
                  backgroundColor: "#E5E5E5",
                  borderRadius: "50%",
                  padding: "10px",
                }}
                className="cursor-pointer"
                icon={faCamera}
                onClick={handleClick}
              />
            </label>
            <input
              ref={imageRef}
              id="image-upload-button"
              type="file"
              accept="image/*"
              onChange={handleImageSelect}
              style={{ display: "none" }}
            />
          </Photodiv>
          {/* {selectedImage && (
          <img
            src={selectedImage}
            alt="Selected Image"  
            width="150"
            height="150"
          />
        )} */}
          {/* {previewImage && <img src={previewImage} alt="Selected image" width="100" height="100"   />} */}
          <section className="grid grid-cols-2 gap-4">
            <div>
              <h3>User's Name</h3>
              <Input
                required
                placeholder="Enter course name"
                type="text"
                name="name"
                value={apiData.name || data?.name}
                onChange={handleInputChange}
                disabled={!isEditable}
              />
            </div>
            <div>
              <h3>Mobile Number</h3>
              <Input
                required
                placeholder="Enter Mobile Number"
                type="text"
                name="phone"
                value={apiData.phone || data?.phone}
                onChange={handleInputChange}
                disabled={!isEditable}
              />
            </div>
          </section>
          <section className="grid grid-cols-2 gap-4">
            <div>
              <div className="flex flex-col gap-2">
                <h3>Email</h3>
                <Input
                  required
                  placeholder="Enter Email"
                  type="text"
                  name="email"
                  value={apiData.email || data?.email}
                  onChange={handleInputChange}
                  disabled={!isEditable}
                />
              </div>
            </div>
            <div>
              {/* <div>
                <div className="flex flex-col gap-2">
                  <h3>Password</h3>
                  <Input
                    required
                    placeholder="Enter Password"
                    type="password"
                    name="password"
                    value={password}
                    onChange={(e) => handleInputChange(e)}
                    disabled={!isEditable}
                  />
                </div>
              </div> */}
            </div>
          </section>
          {isEditable ? (
            <div className="flex justify-center items-center">
              <Button disabled={isSubmitting} type="submit">
                Save
              </Button>
            </div>
          ) : (
            <div className="flex justify-center items-center">
              <Button type="button" onClick={toggleEditMode}>
                Edit
              </Button>
            </div>
          )}
          {isSubmitting && <span>Loading...</span>}
          <ToastContainer
            position="top-center"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            success
            theme="dark"
          />
        </form>
      </Firstdiv>
    </>
  );
};
export default Profilepage;
