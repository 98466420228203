import React, { useState } from "react";
import styled from "styled-components";
import axios from "axios";
import { baseURL } from "../utils/api";
// import signupimg from "../assets/signup.svg";
import frameone from "../assets/frameOne.png";
import { useNavigate } from "react-router-dom";
import frametwo from "../assets/frameTwo.png";
import { Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { BsEye, BsEyeSlash } from "react-icons/bs";

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0% 30% 0% 0%;
  gap: 5vw;
  align-items: center;
  background-color: #9dc6fe;
  @media (min-width: 1024px) and (max-width: 1281px) {
    width: 70%;
    height: 100%;
  }
`;
const SignupImgDiv = styled.div``;

const FomrmWrap = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1px;
  @media (min-width: 1024px) and (max-width: 1281px) {
    ${"" /* height:100%; */}
  }
`;
const Heading = styled.h3`
  font-family: "Lora";
  font-style: normal;
  font-weight: 500;
  font-size: 45px;
`;

const ToLearn = styled.p`
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  color: #124e9f;
  margin-top: 15px;
  letter-spacing: 1.5px;
  @media (min-width: 1024px) and (max-width: 1281px) {
    font-size: 2rem;

    font-family: "Source Sans Pro";
  }
`;

const InputWrapper = styled.div`
  margin-top: 3vmax;
  display: flex;
  flex-direction: column;
  gap: 20px;
  @media (min-width: 1024px) and (max-width: 1281px) {
    font-size: 2rem;

    font-family: "Source Sans Pro";
  }
`;
const InputLabel = styled.label`
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  ::placeholder {
    font-family: "Source Sans Pro";
    font-size: 15px;
  }
  @media (min-width: 1024px) and (max-width: 1281px) {
    font-size: 2rem;

    font-family: "Source Sans Pro";
  }
`;

const Input = styled.input`
  border: none;
  border-bottom: 1px solid black;
  outline: none;
  /* border-bottom: 0; */
  padding-bottom: 5px;
  background: transparent;
  width: 30vmax;
  padding-top: 10px;
  font-weight: 400;
  ::placeholder {
    font-family: "Source Sans Pro";
    color: #787878;
    font-size: 17px;
  }
  @media (min-width: 1024px) and (max-width: 1281px) {
    font-size: 2rem;

    font-family: "Source Sans Pro";
    ::placeholder {
      font-family: "Source Sans Pro";
      color: #787878;
      font-size: 28px;
    }
  }
`;

const Button = styled.button`
  color: #ffffff;
  background: #e07f50;
  border-radius: 40px;
  width: 27vmax;
  border: none;
  outline: none;
  height: 3.5vmax;
  cursor: pointer;
  font-weight: bold;
  font-size: 20px;
  margin-top: 30px;
  &:focus {
    outline: 1px solid black;
  }
  @media (min-width: 1024px) and (max-width: 1281px) {
    font-size: 2rem;
    margin-top: 2rem;
    margin-bottom: 1rem;
    font-family: "Source Sans Pro";
  }
`;
const SginupImg = styled.img`
  width: 44.5vw;
  height: 97.4vh;
`;
const SginupImg1 = styled.img`
  width: 44.5vw;
`;
const Icon = styled.img`
  margin-left: -8rem;
  height: 68px;
  margin-top: 25rem;
  position: absolute;
  z-index: 1;
`;

const SignUp = () => {
  const { handleSubmit, formState } = useForm();
  const { isSubmitting } = formState;

  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    password: "",
  });
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
  const [showPassword, setShowPassword] = React.useState(false);

  const submitForm = async (e) => {
    try {
      const form = new FormData();
      form.append("name", formData.name);
      form.append("email", formData.email);
      form.append("phone", formData.phone);
      form.append("password", formData.password);
      const { data } = await axios.post(`${baseURL}/admin/signup/admin`, form, {
        headers: {
          "Content-Type": "application/json",
        },
      });

      navigate("/login");
    } catch (error) {
      toast.error("Something went wrong");
    }
  };

  return (
    <>
      <Wrapper>
        <SignupImgDiv>
          <SginupImg src={frameone} />
          <Icon src={frametwo} />
        </SignupImgDiv>
        <FomrmWrap onSubmit={handleSubmit(submitForm)}>
          {/* <Heading>Sign up</Heading> */}
          <ToLearn>To learn more about us</ToLearn>
          <InputWrapper>
            <InputLabel>Name</InputLabel>
            <Input
              name="name"
              value={formData.name}
              onChange={handleChange}
              required
              placeholder="Enter your name"
              type="text"
            />
          </InputWrapper>
          <InputWrapper>
            <InputLabel>Mobile</InputLabel>
            <Input
              name="phone"
              value={formData.phone}
              onChange={handleChange}
              required
              placeholder="Enter your mobile number"
              type="text"
            />
          </InputWrapper>
          <InputWrapper>
            <InputLabel>Email</InputLabel>
            <Input
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
              placeholder="Enter your email address"
              type="email"
            />
          </InputWrapper>
          <InputWrapper>
            <Form.Group controlId="formBasicPassword">
              <InputLabel>Password</InputLabel>
              <div style={{ display: "flex" }}>
                <Input
                  required
                  name="password"
                  placeholder="Enter password"
                  type={showPassword ? "text" : "password"}
                  value={formData.password}
                  onChange={handleChange}
                />
                <div
                  style={{ marginLeft: "-2rem" }}
                  className="password-toggle"
                  onClick={() => setShowPassword(!showPassword)}
                >
                  {showPassword ? <BsEyeSlash /> : <BsEye />}
                </div>
              </div>
            </Form.Group>
          </InputWrapper>
          <center>
            <Button type="submit">Sign up</Button>
          </center>
          {isSubmitting && (
            <span style={{ fontWeight: "bold", marginLeft: "1vmax" }}>
              Loading...
            </span>
          )}
          <ToastContainer
            position="top-center"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            success
            theme="dark"
          />
        </FomrmWrap>
      </Wrapper>
    </>
  );
};

export default SignUp;
