import styled from "styled-components";

export const Typography1 = styled.label`
  font-size: 1.5rem;
  font-weight: 500;
  @media (min-width: 1024px) and (max-width: 1281px) {
    font-size: 2rem;
  }
`;
export const Maindiv = styled.div`
  display: flex;
  flex-direction: column;
  width: 75%;
  margin-left: 20rem;
  margin-top: -50vmax;
  @media (min-width: 1024px) and (max-width: 1281px) {
    font-size: 10px;

    width: 70%;
  }
`;
export const Label = styled.label`
  background: #9dc6fe;
  box-sizing: border-box;
  border: 1px solid #e1e1e1;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.14);
  border-radius: 2px;
  margin-left: 4rem;
  font-family: Source Sans Pro;
  color: #787878;
  font-size: 15px;
  color: #063c85;
  width: 10rem;
  display: inherit;
  font-weight: 600;
  text-align: center;
  padding: 9px;
  cursor:pointer;
`;
export const Seconddiv = styled.div`
  width: 40%;
`;
export const Input = styled.input`
  /* border-bottom: 0; */
  ${"" /* padding-bottom: 5px; */}
  ${"" /* height:2rem; */}
    width: 10vmax;
  height: 2rem;
  font-weight: 400;
  padding: 7px;
  background: #ffffff;
  border: 1px solid #e1e1e1;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.14);
  border-radius: 3px;
  ::placeholder {
    font-family: "Source Sans Pro";
    color: #848484;
    font-size: 15px;
  }
  @media (min-width: 1024px) and (max-width: 1281px) {
    width: 115%;
    height: 2rem;
    ::placeholder {
      font-family: "Source Sans Pro";
      color: #787878;
      font-size: 20px;
    }
  }
`;
export const InputO1 = styled.input`
  width: 10vmax;
  height: 2rem;
  font-weight: 400;
  padding: 7px;
  background: #ffffff;
  border: 1px solid #e1e1e1;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.14);
  border-radius: 3px;
  ::placeholder {
    font-family: "Source Sans Pro";
    color: #848484;
    font-size: 15px;
  }
  @media (min-width: 1024px) and (max-width: 1281px) {
    width: 115%;
    height: 2rem;
    ::placeholder {
      font-family: "Source Sans Pro";
      color: #787878;
      font-size: 20px;
    }
  }
`;
export const InputLabel = styled.label`
  padding: 0px;
  font-weight: 600;
  width: 11rem;
  ${"" /* margin:2rem; */}
  ${"" /* margin-left:10rem; */}
  text-align:left;
  font-size: 20px;
  color: #000000;
  display: flex;

  ::placeholder {
    font-family: "Source Sans Pro";
    font-size: 15px;
  }
  @media (min-width: 1024px) and (max-width: 1281px) {
    width: 50%;
    font-size: 2rem;
    font-weight: 500;
    margin-bottom: 2rem;
  }
`;
export const Fulldiv = styled.div`
  display: flex;
  flexdirection: row;
`;
export const Firstdiv = styled.div`
  width: 40%;
`;

export const Button = styled.button`
  background: #e07f50;
  border-radius: 40px;
  border: none;
  outline: none;
  font-family: "Source Sans Pro";
  cursor: pointer;
  margin-top: 1rem;
  /* identical to box height */
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  padding: 0.8rem 3rem;
  color: #ffffff;

  /* Inside auto layout */
  @media (min-width: 1024px) and (max-width: 1281px) {
    ${"" /* margin-left:1px; */}
    margin-left: 40rem;
  }
`;
export const SearchProfile = styled.div`
  margin-left: 0rem;
  margin-bottom: 3rem;
  margin-top: -8rem;

  width: 99%;
  height: 3rem;
  @media (min-width: 1024px) and (max-width: 1281px) {
    margin-left: 4rem;
  }
`;
export const Profile = styled.img`
  float: right;
  margin-right: 1rem;
  cursor: pointer;
  margin-top: -2.6rem;
  @media (min-width: 1024px) and (max-width: 1281px) {
    margin-right: 3rem;
  }
`;
export const Bell = styled.img`
  float: right;
  cursor: pointer;
  display: flex;
  margin-right: 5.5rem;
  margin-top: -2.2rem;
`;
export const Searchdiv = styled.div`
  display: flex;

  @media (min-width: 1024px) and (max-width: 1281px) {
    margin-left: -4rem;
    margin-right: 2rem;
  }
`;
export const Inputs = styled.input`
  width: 80%;
  margin-top: 10px;
  height: 34px;
  margin-left: 1rem;
  border: none;

  ::placeholder {
    font-family: "Source Sans Pro";
    color: #848484;
    font-size: 15px;
  }
  @media (min-width: 1024px) and (max-width: 1281px) {
    width: 73%;
    height: 2rem;
    ::placeholder {
      font-family: "Source Sans Pro";
      color: #787878;
      font-size: 20px;
    }
  }
`;
export const Heading = styled.h1`
  margin-left: 2rem;
  @media (min-width: 1024px) and (max-width: 1281px) {
    margin-left: -1rem;
    width: 30%;
    font-size: 2rem;
  }
`;
export const InputLabel1 = styled.label`
  padding: 0px;
  font-weight: 600;
  width: 11rem;
  ${"" /* margin:2rem; */}
  ${"" /* margin-left:10rem; */}
  text-align:left;
  font-size: 20px;
  color: #000000;
  display: flex;
  margin-bottom: 1rem;
  ::placeholder {
    font-family: "Source Sans Pro";
    font-size: 15px;
  }
  @media (min-width: 1024px) and (max-width: 1281px) {
    width: 50%;
    font-size: 2rem;
    font-weight: 500;
    margin-bottom: 2rem;
  }
`;

export const InputLabel2 = styled.label`
  padding: 0px;
  font-weight: 600;
  width: 11rem;
  ${"" /* margin:2rem; */}
  ${"" /* margin-left:10rem; */}
  text-align:left;
  font-size: 20px;
  color: #000000;
  display: flex;
  margin-top: 2.5rem;
  margin-bottom: -1rem;
  ::placeholder {
    font-family: "Source Sans Pro";
    font-size: 15px;
  }
  @media (min-width: 1024px) and (max-width: 1281px) {
    width: 50%;
    font-size: 2rem;
    font-weight: 500;
    margin-bottom: 2rem;
  }
`;