import React from "react";
import styled from "styled-components";
import eazylearn from "../assets/newlogo.svg";
import dashboard from "../assets/dashboard.png";
import group from "../assets/group.png";
import filecopy from "../assets/filecopy.png";
import membership from "../assets/membership.png";
import help from "../assets/help.png";
import logout from "../assets/logout.png";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { Nav } from "react-bootstrap";
// import {  } from "react-router-dom";

const Img = styled.img`
  width: 10rem;
  margin-left: 3rem;
  margin-top: 2.5rem;
`;

const Logo = styled.img`
  margin-right: 2rem;
`;
const Logout = styled.img`
  border: 1px solid red;
  margin-left: 3.5rem;
  margin-top: 12rem;

  height: 3rem;
  margin-top: 3rem;
`;
const ButtonDiv = styled.div`
  width: 90px;
  margin-left: 16px;
  margin-top: 10rem;
  display: flex;
  cursor: pointer;
`;
const Button = styled.button`
  color: #063c85;
  font-family: Source Sans Pro;
  border: none;
  background: transparent;
  width: 67px;
  height: 30px;
  margin-left: 15px;
  cursor: pointer;
  font-size: 17px;
  font-weight: 700;
`;

// const Sidebar = styled.div`
//   width: 200px;
//   height: 100%;
//   background-color: #eee;
//   position: fixed;
//   top: 0;
//   left: 0;
// `;
// const StyledComponent = styled.div.attrs((props) => ({
//   className: props.className,
// }))`
// .active{
//  background-color: #eee;

// }
// `;
const Dotteddiv = styled.div`
  border-left: 1px dotted black;
  height: 100%;
  position: fixed;
  left: 21rem;
`;
const SidebarWrapper = styled.div`
  .sidebar {
    ${"" /* background-color: #eee; */}
  }
`;

const Sidebar = () => {
  const navigate = useNavigate();
  const { userId } = useParams();
  const { pathname } = useLocation();
  const [activeItem, setActiveItem] = React.useState(null);

  const openDashboard = (item) => {
    navigate("/");
    setActiveItem(item);
  };

  const openUser = () => {
    navigate("/users");
  };
  const handleClick = () => {
    localStorage.removeItem("elearnadmin");
    navigate("/login");
  };
  const openCertificate = (item) => {
    navigate("/certificate");
    setActiveItem(item);
  };
  const openContent = (item) => {
    navigate("/content");
    setActiveItem(item);
  };
  const openHelpSupport = (item) => {
    navigate("/helpsupport");
    setActiveItem(item);
  };

  return (
    <>
      <Dotteddiv></Dotteddiv>
      <div style={{ width: "19%", height: "100%", position: "relative" }}>
        <aside>
          <Img src={eazylearn} />
          <SidebarWrapper>
            <div className="sidebar">
              <ul style={{ width: "20rem", marginTop: "5rem" }}>
                <li
                  className={
                    pathname == "/" ? "sideBarLi  active" : "sideBarLi "
                  }
                  onClick={openDashboard}
                  // className={activeItem === "/" ? "active" : ""}
                  style={{ cursor: "pointer" }}
                >
                  <Logo src={dashboard} />
                  Dashboard
                </li>
                <li
                  className={
                    pathname == "/users" ? "sideBarLi  active" : "sideBarLi "
                  }
                  onClick={openUser}
                  // className={activeItem === "/certificate" ? "active" : ""}
                  style={{ cursor: "pointer" }}
                >
                  <Logo src={group} />
                  Users
                </li>
                <li
                  className={
                    pathname == "/content" || pathname === "/content/learn"
                      ? "sideBarLi  active"
                      : "sideBarLi "
                  }
                  onClick={openContent}
                  // className={activeItem === "/users" ? "active" : ""}
                  style={{ cursor: "pointer" }}
                >
                  <Logo src={filecopy} style={{ cursor: "pointer" }} />
                  Content
                </li>
                <li
                  className={
                    pathname == "/categories"
                      ? "sideBarLi  active"
                      : "sideBarLi "
                  }
                  onClick={() => navigate("/categories")}
                  // className={activeItem === "/users" ? "active" : ""}
                  style={{ cursor: "pointer" }}
                >
                  <Logo src={filecopy} style={{ cursor: "pointer" }} />
                  Sub Categories
                </li>
                <li
                  className={
                    pathname == "/all_content"
                      ? "sideBarLi  active"
                      : "sideBarLi "
                  }
                  onClick={() => navigate("/all_content")}
                  // className={activeItem === "/users" ? "active" : ""}
                  style={{ cursor: "pointer" }}
                >
                  <Logo src={filecopy} style={{ cursor: "pointer" }} />
                  All Content
                </li>
                <li
                  className={
                    pathname === "/all_levels"
                      ? "sideBarLi  active"
                      : "sideBarLi "
                  }
                  onClick={() => navigate("/all_levels")}
                  // className={activeItem === "/users" ? "active" : ""}
                  style={{ cursor: "pointer" }}
                >
                  <Logo src={filecopy} style={{ cursor: "pointer" }} />
                  All Level
                </li>
                {/* <li
                  className={
                    pathname == "/certificate"
                      ? "sideBarLi  active"
                      : "sideBarLi "
                  }
                  onClick={openCertificate}
                  className={activeItem === "/content" ? "active" : ""}
                  style={{ cursor: "pointer" }}
                >
                  <Logo src={membership} />
                  Certificate
                </li> */}
                <li
                  className={
                    pathname == "/helpsupport"
                      ? "sideBarLi  active"
                      : "sideBarLi "
                  }
                  onClick={openHelpSupport}
                  // className={activeItem === "/helpsupport" ? "active" : ""}
                  style={{ cursor: "pointer" }}
                >
                  <Logo src={help} />
                  Help & Support
                </li>
                <li
                  className={
                    pathname == "/blogs" ? "sideBarLi  active" : "sideBarLi "
                  }
                  onClick={() => navigate("/blogs")}
                  // className={activeItem === "/helpsupport" ? "active" : ""}
                  style={{ cursor: "pointer" }}
                >
                  <Logo src={help} />
                  Blogs
                </li>
                <li
                  className={
                    pathname == "/blogs" ? "sideBarLi  active" : "sideBarLi "
                  }
                  onClick={handleClick}
                  // className={activeItem === "/helpsupport" ? "active" : ""}
                  style={{ cursor: "pointer",paddingBottom:"20px" }}
                >
                  {/* <Logo src={help} /> */}
                  LOGOUT
                </li>

                {/* <ButtonDiv>
                  <img
                    onClick={handleClick}
                    src={logout}
                    style={{ width: "25px", height: "25px" }}
                  />
                  <Button onClick={handleClick}>Logout</Button>
                </ButtonDiv> */}
              </ul>
            </div>
          </SidebarWrapper>
        </aside>
      </div>
    </>
  );
};

export default Sidebar;
