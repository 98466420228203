import React, { useState, useReducer } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Typography1,
  Maindiv,
  Label,
  Seconddiv,
  Input,
  InputO1,
  InputLabel,
  Fulldiv,
  Firstdiv,
  Button,
  InputLabel1,
  InputLabel2,
} from "../../Components/DesignComponents";
import { baseURL } from "../../utils/api";
import { Accordion, AccordionSummary, IconButton, Stack } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import axios from "axios";
import FormLoader from "../../Components/FormLoader";
import { useForm } from "react-hook-form";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AccordionDetails from "@mui/material/AccordionDetails";

const VocabLevel1 = ({ type }) => {
  const { handleSubmit, formState } = useForm();
  const { isSubmitting } = formState;

  function reducer(state, action) {
    if (action.type === "TAMIL_OPTIONS") {
      const { payload } = action;
      return {
        ...state,
        tamilOptions: { ...state.tamilOptions, [payload.field]: payload.value },
      };
    } else if (action.type === "HINDI_OPTIONS") {
      const { payload } = action;
      return {
        ...state,
        hindiOptions: { ...state.hindiOptions, [payload.field]: payload.value },
      };
    } else if (action.type === "ENGLISH_OPTIONS") {
      const { payload } = action;
      return {
        ...state,
        options: {
          ...state.options,
          [payload.field]: payload.value,
        },
      };
    } else if (action.type === "TELUGU_OPTIONS") {
      const { payload } = action;
      return {
        ...state,
        teluguOptions: {
          ...state.teluguOptions,
          [payload.field]: payload.value,
        },
      };
    } else if (action.type === "ANSWER") {
      const { payload } = action;
      return {
        ...state,
        [payload.field]: payload.value,
      };
    } else if (action.type === "CHANGE_IMAGE") {
      return {
        ...state,
        image: action.payload,
      };
    }
    return state;
  }

  const initialState = {
    options: {
      a: "",
      b: "",
      c: "",
      d: "",
    },
    answer: "",
  };
  const [state, dispatch] = useReducer(reducer, initialState);

  function handleEnglishOptions(e) {
    const field = e.target.name;
    const value = e.target.value;
    dispatch({
      type: "ENGLISH_OPTIONS",
      payload: {
        field,
        value,
      },
    });
  }

  function handleImage(e) {
    dispatch({
      type: "CHANGE_IMAGE",
      payload: e.target.files[0],
    });
  }

  function handleAnswers(e) {
    const field = e.target.name;
    const value = e.target.value;
    dispatch({
      type: "ANSWER",
      payload: {
        field,
        value,
      },
    });
  }
  const [text, setText] = useState("");
  const submitForm = async () => {
    const formData = new FormData();

    formData.append("options", JSON.stringify(state.options));
    formData.append("answer", state.answer);
    formData.append("text", text);
    try {
      const { data } = await axios.post(
        `http://localhost:5000/api/play/upload/vocab/level1`,
        formData,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      toast("Level added");
    } catch (error) {
      console.log(error);
      toast.error("something went wrong");
    }
  };
  const open = true;
  return (
    <>
      <form onSubmit={handleSubmit(submitForm)}>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography1>Level-1 (Fill in The blank)</Typography1>
          </AccordionSummary>
          <Stack direction="row" alignItems="center" spacing={2}>
            <AccordionDetails>
              <AccordionDetails>
                <Fulldiv style={{ display: "flex", flexDirection: "row" }}>
                  <Firstdiv style={{ width: "40%" }}>
                    <InputLabel>Question</InputLabel>
                    <Input
                      required
                      placeholder="Write Question"
                      value={text}
                      onChange={(e) => setText(e.target.value)}
                      type="text"
                    />
                  </Firstdiv>
                </Fulldiv>
              </AccordionDetails>
            </AccordionDetails>
          </Stack>

          <AccordionDetails>
            <AccordionDetails>
              <InputLabel1> options</InputLabel1>
              <div className="grid grid-cols-4">
                <Firstdiv>
                  <InputLabel>Option-1</InputLabel>
                  <InputO1
                    name="a"
                    value={state.options.a}
                    onChange={handleEnglishOptions}
                    required
                    placeholder="Option-1"
                    type="text"
                  />
                </Firstdiv>

                <div>
                  <InputLabel>Option-2</InputLabel>
                  <InputO1
                    name="b"
                    value={state.options.b}
                    onChange={handleEnglishOptions}
                    required
                    placeholder="Option-2"
                    type="text"
                  />
                </div>
                <div>
                  <InputLabel>Option-3</InputLabel>
                  <InputO1
                    name="c"
                    value={state.options.c}
                    onChange={handleEnglishOptions}
                    required
                    placeholder="Option-3"
                    type="text"
                  />
                </div>
                <div>
                  <InputLabel>Option-4</InputLabel>
                  <InputO1
                    name="d"
                    value={state.options.d}
                    onChange={handleEnglishOptions}
                    required
                    placeholder="Option-4"
                    type="text"
                  />
                </div>
              </div>

              <div className="grid grid-cols-4 mt-4">
                <div>
                  <InputLabel>Correct Option</InputLabel>
                  <Input
                    name="answer"
                    value={state.answer}
                    onChange={handleAnswers}
                    required
                    placeholder="Enter Answer"
                    type="text"
                  />
                </div>
              </div>

              <div className="flex justify-center items-center">
                <Button type="submit">Upload</Button>
              </div>

              {isSubmitting && <FormLoader open={open} />}
              <ToastContainer
                position="top-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                success
                theme="dark"
              />
            </AccordionDetails>
          </AccordionDetails>
        </Accordion>
      </form>
    </>
  );
};

export default VocabLevel1;
