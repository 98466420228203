import React, { useReducer } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Typography1,
  Maindiv,
  Label,
  Seconddiv,
  Input,
  InputO1,
  InputLabel,
  Fulldiv,
  Firstdiv,
  Button,
  SearchProfile,
  Profile,
  Searchdiv,
  Bell,
  Inputs,
  Heading,
  InputLabel1,
  InputLabel2,
} from "../../Components/DesignComponents";
import { baseURL } from "../../utils/api";
import { Accordion, AccordionSummary, IconButton, Stack } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import axios from "axios";
import FormLoader from "../../Components/FormLoader";
import { useForm } from "react-hook-form";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AccordionDetails from "@mui/material/AccordionDetails";


const WordLevel1 = () => {
  const { handleSubmit, formState } = useForm();
  const { isSubmitting } = formState;

  function reducer(state, action) {
    if (action.type === "TAMIL_OPTIONS") {
      const { payload } = action;
      return {
        ...state,
        tamilOptions: { ...state.tamilOptions, [payload.field]: payload.value },
      };
    } else if (action.type === "HINDI_OPTIONS") {
      const { payload } = action;
      return {
        ...state,
        hindiOptions: { ...state.hindiOptions, [payload.field]: payload.value },
      };
    } else if (action.type === "ENGLISH_OPTIONS") {
      const { payload } = action;
      return {
        ...state,
        options: {
          ...state.options,
          [payload.field]: payload.value,
        },
      };
    } else if (action.type === "TELUGU_OPTIONS") {
      const { payload } = action;
      return {
        ...state,
        teluguOptions: {
          ...state.teluguOptions,
          [payload.field]: payload.value,
        },
      };
    } else if (action.type === "ANSWER") {
      const { payload } = action;
      return {
        ...state,
        [payload.field]: payload.value,
      };
    } else if (action.type === "CHANGE_IMAGE") {
      return {
        ...state,
        image: action.payload,
      };
    }
    return state;
  }

  const initialState = {
    image: "",

    options: {
      a: "",
      b: "",
      c: "",
      d: "",
    },
    answer: "",
  };
  const [state, dispatch] = useReducer(reducer, initialState);

  function handleEnglishOptions(e) {
    const field = e.target.name;
    const value = e.target.value;
    dispatch({
      type: "ENGLISH_OPTIONS",
      payload: {
        field,
        value,
      },
    });
  }

  function handleImage(e) {
    dispatch({
      type: "CHANGE_IMAGE",
      payload: e.target.files[0],
    });
  }

  function handleAnswers(e) {
    const field = e.target.name;
    const value = e.target.value;
    dispatch({
      type: "ANSWER",
      payload: {
        field,
        value,
      },
    });
  }

  const submitForm = async () => {
    const formData = new FormData();

    formData.append("options", JSON.stringify(state.options));
    formData.append("answer", state.answer);

    formData.append("image", state.image);
    try {
      const { data } = await axios.post(
        `${baseURL}/play/upload/word/level1`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      toast("Level added");
    } catch (error) {
      toast.error("something went wrong");
    }
  };
  const open = true;
  return (
    <>
      <form onSubmit={handleSubmit(submitForm)}>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography1>Level-1</Typography1>
          </AccordionSummary>
          <Stack direction="row" alignItems="center" spacing={2}>
            <Firstdiv>
              <Label for="image">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  class="bi bi-cloud-arrow-up-fill"
                  viewBox="0 0 20 10"
                >
                  <path d="M8 2a5.53 5.53 0 0 0-3.594 1.342c-.766.66-1.321 1.52-1.464 2.383C1.266 6.095 0 7.555 0 9.318 0 11.366 1.708 13 3.781 13h8.906C14.502 13 16 11.57 16 9.773c0-1.636-1.242-2.969-2.834-3.194C12.923 3.999 10.69 2 8 2zm2.354 5.146a.5.5 0 0 1-.708.708L8.5 6.707V10.5a.5.5 0 0 1-1 0V6.707L6.354 7.854a.5.5 0 1 1-.708-.708l2-2a.5.5 0 0 1 .708 0l2 2z" />
                </svg>
                Upload image-1
              </Label>
              <div
                style={{
                  marginTop: "1vh",
                  display: "flex",
                  justifyContent: "center",
                  textAlign: "center",
                }}
              >
                <span>{state.image?.name}</span>
              </div>
              <input
                type="file"
                style={{ display: "none" }}
                id="image"
                name="image"
                onChange={handleImage}
                accept="image/*"
              />
              <IconButton
                color="primary"
                aria-label="upload picture"
                component="label"
              >
                {/* <input hidden accept="image/*" type="file" /> */}
              </IconButton>
            </Firstdiv>
          </Stack>

          <Stack direction="row" alignItems="center" spacing={2}>
            <AccordionDetails>
              <AccordionDetails>
                <Fulldiv style={{ display: "flex", flexDirection: "row" }}>
                  <Firstdiv style={{ width: "40%" }}>
                    <InputLabel>Question</InputLabel>
                    <Input required placeholder="Question" type="text" />
                  </Firstdiv>
                </Fulldiv>
              </AccordionDetails>
            </AccordionDetails>
          </Stack>
          <AccordionDetails>
            <AccordionDetails>
              <InputLabel1> options</InputLabel1>
              <div className="grid grid-cols-4">
                <Firstdiv>
                  <InputLabel>Option-1</InputLabel>
                  <InputO1
                    name="a"
                    value={state.options.a}
                    onChange={handleEnglishOptions}
                    required
                    placeholder="Option-1"
                    type="text"
                  />
                </Firstdiv>

                <div>
                  <InputLabel>Option-2</InputLabel>
                  <InputO1
                    name="b"
                    value={state.options.b}
                    onChange={handleEnglishOptions}
                    required
                    placeholder="Option-2"
                    type="text"
                  />
                </div>
                <div>
                  <InputLabel>Option-3</InputLabel>
                  <InputO1
                    name="c"
                    value={state.options.c}
                    onChange={handleEnglishOptions}
                    required
                    placeholder="Option-3"
                    type="text"
                  />
                </div>
                <div>
                  <InputLabel>Option-4</InputLabel>
                  <InputO1
                    name="d"
                    value={state.options.d}
                    onChange={handleEnglishOptions}
                    required
                    placeholder="Option-4"
                    type="text"
                  />
                </div>
              </div>

              <div className="grid grid-cols-4 mt-4">
                <div>
                  <InputLabel>Correct Option</InputLabel>
                  <Input
                    name="answer"
                    value={state.answer}
                    onChange={handleAnswers}
                    required
                    placeholder="Enter Answer"
                    type="text"
                  />
                </div>
              </div>

              <div className="flex justify-center items-center">
                <Button type="submit">Upload</Button>
              </div>

              {isSubmitting && <FormLoader open={open} />}
              <ToastContainer
                position="top-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                success
                theme="dark"
              />
            </AccordionDetails>
          </AccordionDetails>
        </Accordion>
      </form>
    </>
  );
};

export default WordLevel1;
