import React, { useState } from "react";
import styled from "styled-components";
import Sidebar from "./Sidebar";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import bell from "../assets/bell.png";
import profileimage from "../assets/profileimage.png";
import "react-datepicker/dist/react-datepicker.css";
import { useNavigate } from "react-router-dom";
import { Modal } from "@mui/material";
import { useFetch } from "../utils/customHook";
import axios from "axios";
import { baseURL } from "../utils/api";
import { useForm } from "react-hook-form";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import HelpTable from "./HelpTable";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  border: "none",
  boxShadow: 24,
  p: 4,
};
const Firstdiv = styled.p`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  float: left;
  margin-left: 26rem;
  margin-top: -28rem;
  @media (min-width: 1024px) and (max-width: 1281px) {
    width: 65%;
  }
`;
const InputLabel = styled.label`
  font-family: "Inter";
  padding: 7px;
  font-style: normal;
  font-weight: 600;
  margin-top: 2rem;
  width: 14rem;
  text-align: left;
  font-size: 18px;
  color: #4a4a4a;
  display: flex;
  ::placeholder {
    font-family: "Source Sans Pro";
    font-size: 15px;
  }
  @media (min-width: 1024px) and (max-width: 1281px) {
    width: 90%;
    font-size: 2rem;
  }
`;

const Seconddiv = styled.p`
  float: right;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-right: 4rem;
  margin-top: -31rem;

  @media (min-width: 1024px) and (max-width: 1281px) {
    margin-left: -4rem;
    margin-right: -5rem;
    margin-top: -32rem;
  }
`;
const Button = styled.button`
  background: #e07f50;
  border-radius: 40px;
  border: none;
  outline: none;
  font-family: "Source Sans Pro";
  cursor: pointer;
  width: 7rem;
  margin-left: 2rem;
  /* identical to box height */
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  padding: 0.5rem 1.5rem;
  color: #ffffff;

  /* Inside auto layout */
  @media (min-width: 1024px) and (max-width: 1281px) {
    ${"" /* margin-left:1px; */}
    margin-left: 15rem;
    margin-top: 2rem;
  }
`;

const TextArea = styled.textarea`
  width: 26vmax;
  background: #ffffff;
  border: 1px solid #e1e1e1;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.14);
  border-radius: 2px;
  ::placeholder {
    font-family: "Source Sans Pro";
    color: #787878;
    font-size: 17px;
    padding-left: 0.3rem;
  }
  @media (min-width: 1024px) and (max-width: 1281px) {
    font-size: 2px;
    height: 2rem;
    margin-top: 2rem;
    ::placeholder {
      font-family: "Source Sans Pro";
      color: #787878;
      font-size: 25px;
    }
  }
`;
const SearchProfile = styled.div`
  ${"" /* margin-left: 20rem; */}
  margin-bottom: 3rem;
  margin-top: -12rem;
  width: 60vmax;

  height: 3rem;
  @media (min-width: 1024px) and (max-width: 1281px) {
    margin-left: 4rem;
  }
`;

const Bell = styled.img`
  float: right;
  display: flex;
  margin-right: 1.8rem;
  margin-top: -2rem;
  cursor: pointer;
  @media (min-width: 1024px) and (max-width: 1281px) {
    margin-right: 3rem;
  }
`;
const Profile = styled.img`
  float: right;
  margin-right: 1rem;
  cursor: pointer;
  margin-top: -2.3rem;
  @media (min-width: 1024px) and (max-width: 1281px) {
    margin-right: 7rem;
  }
`;
const Inputdiv = styled.input`
  height: 32px;
  margin-left: -3rem;
  width: 68rem;
  margin-top: 0.5rem;
  border: none;
  @media (min-width: 1024px) and (max-width: 1281px) {
    margin-left: -6rem;
    width: 86%;
  }
`;
const Label = styled.label`
width: 2rem,
 border: none;
 margin-left: -22rem;
    ${"" /* margin-top: 10px; */}
        margin-top: 2.8rem;
`;
const Tablediv = styled.div`
  margin-top: 2rem;
  box-shadow: 0px 6px 26px rgba(0, 0, 0, 0.12);
  border-radius: 10px;
  height: 100%;
  margin-left: -3rem;
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  @media (min-width: 1024px) and (max-width: 1281px) {
    font-size: 1.5rem;
    height: 100%;
    font-family: Inter;
    font-style: normal;
    font-weight: 400;
  }
`;
const Heading = styled.h2`
  font-size: 2rem;
  margin-left: -3rem;
  @media (min-width: 1024px) and (max-width: 1281px) {
    margin-left: -6rem;
  }
`;
const Input = styled.input`
  /* border-bottom: 0; */
  ${"" /* padding-bottom: 5px; */}
  ${"" /* height:2rem; */}
  width: 25vmax;
  ${"" /* padding-top: 10px; */}
  font-weight: 400;
  padding: 7px;
  background: #ffffff;
  border: 1px solid #898989;
  height: 1.5rem;
  border-radius: 3px;
  ::placeholder {
    font-family: "Source Sans Pro";
    color: #848484;
    font-size: 15px;
  }
  @media (min-width: 1024px) and (max-width: 1281px) {
    height: 2rem;
    width: 80%;

    ::placeholder {
      font-family: "Source Sans Pro";
      color: #787878;
      font-size: 18px;
    }
  }
`;
const Searchdiv = styled.div`
  display: flex;
  margin-left: -6rem;
  @media (min-width: 1024px) and (max-width: 1281px) {
    margin-left: -8rem;
    margin-right: 2rem;
  }
`;
const Inputs = styled.input`
  width: 78%;
  margin-top: 10px;
  height: 34px;
  border: none;
  margin-left: 1rem;
  ::placeholder {
    font-family: "Source Sans Pro";
    color: #848484;
    font-size: 15px;
  }
  @media (min-width: 1024px) and (max-width: 1281px) {
    width: 73%;
    height: 2rem;
    ::placeholder {
      font-family: "Source Sans Pro";
      color: #787878;
      font-size: 20px;
    }
  }
`;

const HelpSupport = () => {
  const { handleSubmit, formState } = useForm();
  const { isSubmitting } = formState;

  const [reply, setReply] = useState("");
  const navigate = useNavigate();
  const [state, setState] = useState({
    question: "",
    answer: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState({ ...state, [name]: value });
  };

  const [searchInput, setSearchInput] = React.useState("");

  const openLogin = () => {
    navigate("/profile");
  };
  const openNotification = () => {
    navigate("/notification");
  };

  const submitForm = async () => {
    try {
      await axios.post(
        `${baseURL}/admin/upload/help`,
        {
          question: state.question,
          answer: state.answer,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      toast.success("Uploaded");
    } catch (error) {
      console.log(error);
      toast.error("Something went wrong");
    }
  };

  return (
    <>
      <Sidebar />
      <Firstdiv>
        <SearchProfile>
          
          {/* <Profile src={profileimage} onClick={openLogin} /> */}
          {/* <Bell onClick={openNotification} src={bell} /> */}
          <Heading>Help & Support</Heading>
        </SearchProfile>

        <section className="mt-20">
          <form
            onSubmit={handleSubmit(submitForm)}
            className="flex flex-col gap-3 items-center"
          >
            <div className="flex flex-col gap-2">
              <label htmlFor="" className="font-bold text-lg">
                Question
              </label>
              <input
                required
                value={state.question}
                name="question"
                onChange={handleChange}
                type="text"
                placeholder="Enter Question"
                className="w-[20vw] rounded-md pl-6 p-2"
              />
            </div>
            <div className="flex flex-col gap-2">
              <label htmlFor="" className="font-bold text-lg">
                Answer
              </label>
              <input
                required
                value={state.answer}
                name="answer"
                onChange={handleChange}
                type="text"
                placeholder="Enter Question"
                className="w-[20vw] rounded-md pl-6 p-2"
              />
            </div>
            <div>
              <button
                disabled={isSubmitting}
                className="bg-blue-700 cursor-pointer text-white p-3 rounded-md border-none outline-none"
              >
                Upload
              </button>
            </div>
            {isSubmitting && <span className="font-bold">Loading...</span>}
            <ToastContainer
              position="top-center"
              autoClose={5000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
              success
              theme="dark"
            />
          </form>
        </section>
        <HelpTable />
      </Firstdiv>
    </>
  );
};
export default HelpSupport;
