import React from "react";
import styled from "styled-components";
import Sidebar from "./Sidebar";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import bell from "../assets/bell.png";
import profileimage from "../assets/profileimage.png";
// import dayjs from 'dayjs';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useNavigate } from "react-router-dom";
import { height } from "@mui/system";
import { Modal } from "@mui/material";
import profile from "../assets/profile.png";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
// import Button from '@mui/material/Button';

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  border: "none",
  boxShadow: 26,
  p: 4,
};
const Firstdiv = styled.p`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  float: left;
  margin-left: 26rem;
  margin-top: -28rem;
  @media (min-width: 1024px) and (max-width: 1281px) {
    width: 65%;
  }
`;
const InputLabel = styled.label`
  font-family: "Inter";
  padding: 7px;
  font-style: normal;
  font-weight: 600;
  margin-top: 2rem;
  width: 14rem;
  text-align: left;
  font-size: 18px;
  color: #4a4a4a;
  display: flex;
  ::placeholder {
    font-family: "Source Sans Pro";
    font-size: 15px;
  }
  @media (min-width: 1024px) and (max-width: 1281px) {
    width: 90%;
    font-size: 2rem;
  }
`;

const Seconddiv = styled.p`
  float: right;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-right: 4rem;
  margin-top: -31rem;

  @media (min-width: 1024px) and (max-width: 1281px) {
    margin-left: -4rem;
    margin-right: -5rem;
    margin-top: -32rem;
  }
`;
const Button = styled.button`
  background: #e07f50;
  border-radius: 40px;
  border: none;
  outline: none;
  font-family: "Source Sans Pro";
  cursor: pointer;
  width: 7rem;
  margin-left: 2rem;
  /* identical to box height */
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  padding: 0.5rem 1.5rem;
  color: #ffffff;

  /* Inside auto layout */
  @media (min-width: 1024px) and (max-width: 1281px) {
    ${"" /* margin-left:1px; */}
    margin-left: 40rem;
  }
`;

const TextArea = styled.textarea`
  width: 26vmax;
  background: #ffffff;
  border: 1px solid #e1e1e1;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.14);
  border-radius: 2px;
  ::placeholder {
    font-family: "Source Sans Pro";
    color: #787878;
    font-size: 17px;
    padding-left: 0.3rem;
  }
  @media (min-width: 1024px) and (max-width: 1281px) {
    font-size: 2px;
    height: 2rem;
    margin-top: 2rem;
    ::placeholder {
      font-family: "Source Sans Pro";
      color: #787878;
      font-size: 25px;
    }
  }
`;
const SearchProfile = styled.div`
  ${"" /* border: 1px solid red; */}
  ${"" /* margin-left: 20rem; */}
  margin-bottom: 3rem;
  margin-top: -11rem;
  width: 60vmax;
  height: 3rem;
  @media (min-width: 1024px) and (max-width: 1281px) {
    margin-left: 4rem;
  }
`;

const Bell = styled.img`
  float: right;
  display: flex;
 margin-right: 5.5rem;
      margin-top: -2.5rem;
  @media (min-width: 1024px) and (max-width: 1281px) {
    margin-right: 15rem;
  }
`;
const Profile = styled.img`
  float: right;
  margin-right: 1rem;
margin-top: -2.8rem;
  @media (min-width: 1024px) and (max-width: 1281px) {
      margin-right: 10rem;
    margin-top: -3rem;
  }
`;
const Profile1 = styled.img`
  float: right;

  margin-right: 1rem;
  ${"" /* margin-top: 2rem; */}
  @media (min-width: 1024px) and (max-width: 1281px) {
    margin-right: 2rem;
    ${"" /* margin-top:8rem; */}
  }
`;


const Tablediv = styled.div`
  margin-top: 2rem;
  box-shadow: 0px 6px 26px rgba(0, 0, 0, 0.12);
  background: #fdfeff;
  border-radius: 10px;
  width: 100%;
  height: 100%;
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  @media (min-width: 1024px) and (max-width: 1281px) {
    font-size: 1.5rem;
    width: 120%;
    height: 100%;
    font-family: Inter;
    font-style: normal;
    font-weight: 400;
  }
`;
const Heading = styled.h2`
  font-size: 2rem;
  margin-left: -3rem;
  @media (min-width: 1024px) and (max-width: 1281px) {
    margin-left: -6rem;
  }
`;
const Input = styled.input`
  width: 25vmax;
  font-weight: 400;
  padding: 7px;
  background: #ffffff;
  border: 1px solid #898989;
  height: 1.5rem;
  border-radius: 3px;
  ::placeholder {
    font-family: "Source Sans Pro";
    color: #848484;
    font-size: 15px;
  }
  @media (min-width: 1024px) and (max-width: 1281px) {
    height: 2rem;
    ::placeholder {
      font-family: "Source Sans Pro";
      color: #787878;
      font-size: 20px;
    }
  }
`;
const Inputs = styled.input`
  width: 87%;
  margin-top: 10px;
  height: 34px;
  border: none;
  margin-left: 1rem;
  
  @media (min-width: 1024px) and (max-width: 1281px) {
    width: 90%;
    margin-top: 10px;
    height: 34px;
    margin-left: 1rem;
    border: none;
  }
`;
const Mapdiv = styled.div`
  margin-top: 2rem;
  display: flex;
  cursor: pointer;
  margin-left: -3rem;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 6px 26px rgba(0, 0, 0, 0.12);
  background: #fdfeff;
  border-radius: 10px;
  width: 60vmax;
  padding: 20px;
  height: 10%;
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  @media (min-width: 1024px) and (max-width: 1281px) {
    font-size: 1.5rem;
    width: 60vmax;
    ${
      "" /* width:120%;
  height:100%; */
    }
    font-family: Inter;
    font-style: normal;
    font-weight: 400;
  }
`;
const Searchdiv = styled.div`
  display: flex;

margin-left: -4rem;
    margin-right: 9rem;
  @media (min-width: 1024px) and (max-width: 1281px) {
    margin-left: -7rem;
    width: 81%;
  }
`;

const Notifydiv = styled.div`
    font-family: 'Inter';
      font-style: normal;
display: flex;
flex-direction: column;
align-items: flex-start;
padding: 0px;
gap:1px;
`

const Notify = () => {
  const [description, setDescription] = React.useState();
  const [selectedItem, setSelectedItem] =React.useState(null);
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
  };
 

  // const [open, setOpen] = React.useState(false);
  const handleOpen = (item) => {
    setOpen(true);
    setSelectedItem(item);
    setDescription(item.desc);
  };
  // const handleClose = () => setOpen(false);
  const [searchInput, setSearchInput] = React.useState("");

  const openLogin = () => {
    navigate("/profile");
  };
  const openNotification = () => {
    navigate("/notification");
  };
  const Array = [
    {
      title: "Vitae tincidunt risus elementum faucibus in egestas ipsum ut.",
      desc: "Est quis aut veritatis sed aspernatur assumenda aut. Ducimus ea error. Quisquam repellat eos rem delectus qui dolorum vero exercitationem asperiores. Architecto consequatur suscipit est repellendus. Illo sunt et dolor molestiae repellat vitae rerum eos et. Sit tempora aspernatur laborum dolor quo.Qui voluptatem totam molestias minima. Hic incidunt qui dolores odit dolores. Voluptatibus nisi adipisci et voluptatem soluta. Doloribus est sunt deserunt inventore ut. Voluptatibus asperiores non fugit ipsam delectus dolore hic. Officia accusamus numquam laudantium minus suscipit dicta dolor voluptatem.",
    },
    {
      title: "Vitae tincidunt risus elementum faucibus in egestas ipsum ut.",
      desc: "Tincidunt sit congue platea faucibus vestibulum justo sed nec. Quam non at proin libero ultricies eu imperdiet. Diam quis nulla cursus at dictumst eu. Est netus orci est sit scelerisque. Quam nam id libero tristique sit amet ornare.",
    },
    {
      title: "Vitae tincidunt risus elementum faucibus in egestas ipsum ut.",
      desc: "Tincidunt sit congue platea faucibus vestibulum justo sed nec. Quam non at proin libero ultricies eu imperdiet. Diam quis nulla cursus at dictumst eu. Est netus orci est sit scelerisque. Quam nam id libero tristique sit amet ornare.",
    },
    {
      title: "Vitae tincidunt risus elementum faucibus in egestas ipsum ut.",
      desc: "Tincidunt sit congue platea faucibus vestibulum justo sed nec. Quam non at proin libero ultricies eu imperdiet. Diam quis nulla cursus at dictumst eu. Est netus orci est sit scelerisque. Quam nam id libero tristique sit amet ornare.",
    },
    {
      title: "Vitae tincidunt risus elementum faucibus in egestas ipsum ut.",
      desc: "Tincidunt sit congue platea faucibus vestibulum justo sed nec. Quam non at proin libero ultricies eu imperdiet. Diam quis nulla cursus at dictumst eu. Est netus orci est sit scelerisque. Quam nam id libero tristique sit amet ornare.",
    },
  ];

  return (
    <>
      <Sidebar />
      <Firstdiv>
        <SearchProfile>
        <Searchdiv>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            class="bi bi-search"
            viewBox="0 0 16 16"
            style={{ marginTop: "18px", marginLeft: "1rem",position:'relative' ,right: '-37px',backgroundPosition: 'right center'}}
          >
            <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z " />
            <path d="M0 0h24v24H0z" fill="none"/>
          </svg>
          <Inputs
          className="pl-12"
            // placeholder="Search here..."
            aria-label="Search through site content"
            type="text"
            value={searchInput}
            onChange={(e) => setSearchInput(e.target.value)}
          />
        </Searchdiv>

          <Profile src={profileimage} onClick={openLogin} />  
          <Bell onClick={openNotification} src={bell} />
          <Heading>Notifications</Heading>
        </SearchProfile>

      

        {Array.slice(0, 2).map((item, index) => {
          return (
            <>
              <Mapdiv>
                <div>
                  <Profile1 src={profile} />
                </div>
                <Notifydiv onClick={() => handleOpen(item)}>
                  <h4 style={{marginBottom:'-10px'}}>{item.title}</h4>
                  <p>{item.desc}</p>
                </Notifydiv>
              </Mapdiv>
            </>
          );
        })}
        {selectedItem && (
        <div>
          
          <p>{selectedItem.desc}</p>
        </div>
      )}
      </Firstdiv>
    </>
  );
};
export default Notify;
