import { useState } from "react";
import {
  ListItemIcon,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import axios from "axios";
import DeleteAlert from "./DeleteAlert";
import { useFetch } from "../utils/customHook";
import { baseURL } from "../utils/api";
const HelpTable = () => {
  const [data] = useFetch(`${baseURL}/admin/get/query`);

  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [page, setPage] = useState(0);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const [openSnakbar, setOpenSnackbar] = useState(false);
  const closeSnackbare = () => setOpenSnackbar(false);
  async function deletehelp(helpId) {
    try {
      await axios.get(`${baseURL}/admin/delete/help/${helpId}`);
      setOpenSnackbar(true);
    } catch (error) {}
  }

  return (
    <>
      <main className="mt-10">
        <TableContainer>
          <TableHead>
            <TableRow>
              <TableCell style={{ fontWeight: "600", width: "5vw" }}>
                ID
              </TableCell>

              <TableCell style={{ fontWeight: "600", width: "20vw" }}>
                help Title
              </TableCell>

              <TableCell style={{ fontWeight: "600", width: "30vw" }}>
                Action
              </TableCell>
            </TableRow>
          </TableHead>
          <DeleteAlert
            open={openSnakbar}
            message="Deleted"
            handleClose={closeSnackbare}
          />
          <TableBody>
            {data?.help
              ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((help, index) => {
                return (
                  <TableRow key={help._id}>
                    <TableCell style={{ fontFamily: "Inter" }}>
                      {" "}
                      {index + 1}
                    </TableCell>
                    <TableCell style={{ fontFamily: "Inter" }}>
                      {help.question}
                    </TableCell>

                    <TableCell style={{ fontFamily: "Inter" }}>
                      <div className="flex gap-4">
                        <button
                          onClick={() => deletehelp(help._id)}
                          className="bg-red-700 border-none outline-none cursor-pointer w-[7vw] p-2 rounded-md text-white"
                        >
                          Delete
                        </button>
                      </div>
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </TableContainer>

        <TablePagination
          rowsPerPage={rowsPerPage}
          count={data?.help?.length}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </main>
    </>
  );
};

export default HelpTable;
