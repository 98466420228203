import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Sidebar from "./Sidebar";
import {
  Typography1,
  Maindiv,
  SearchProfile,
  Profile,
  Searchdiv,
  Bell,
  Inputs,
  Heading,
} from "./DesignComponents";
import bell from "../assets/bell.png";
import profileimage from "../assets/profileimage.png";
import { Accordion, AccordionSummary, IconButton, Stack } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AccordionDetails from "@mui/material/AccordionDetails";
import SubcategoryForm from "../Content/SubcategoryForm";
import WordsFruitsUploadForm from "../Content/Words/WordsFruitsUploadForm";
import VocabForm from "../Content/Vocab/VocabForm";
import VideoForm from "../Content/Video/VideoForm";
import NumbersForm from "../Content/Numbers/Numberform";
import WordLevel1 from "../Levels/Words/WordLevel1";
import WordLevel2 from "../Levels/Words/WordLevel2";
import WordLevel3 from "../Levels/Words/WordLevel3";
import NumLevel1 from "../Levels/Numbers/NumLevel1";
import NumLevel2 from "../Levels/Numbers/NumLevel2";
import NumLevel3 from "../Levels/Numbers/NumLevel3";
import VocabLevel1 from "../Levels/Vocabs/VocabLevel1";
import VocabLevel2 from "../Levels/Vocabs/VocabLevel2";
const Learn = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const prop = location.state.xyz;
  const [searchInput, setSearchInput] = React.useState("");
  const openLogin = () => {
    navigate("/profile");
  };
  const openNotification = () => {
    navigate("/notification");
  };

  return (
    <>
      {prop == "Learn" ? (
        <div>
          <Sidebar />
          <Maindiv>
            <SearchProfile>
              <Profile src={profileimage} onClick={openLogin} />
              {/* <Bell onClick={openNotification} src={bell} /> */}
              <Heading>Learn</Heading>
            </SearchProfile>
            <div className="ml-10 mt-10">
              <Accordion className="mt-10 ">
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography1>Words</Typography1>
                </AccordionSummary>
                <Stack direction="column" spacing={3}>
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography1>Add Sub Categories </Typography1>
                    </AccordionSummary>
                    {/* Sub category form */}
                    <SubcategoryForm type="Words" mainCategory={"Words"} />
                  </Accordion>
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography1>Upload Words Categories Content</Typography1>
                    </AccordionSummary>
                    {/* upload content for words , the content will be same just categories will be chosen from form content */}
                    <WordsFruitsUploadForm type={"Words"} />
                  </Accordion>
                </Stack>
              </Accordion>
              {/* vocabulary part */}
              <Accordion className="mt-10 ">
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography1>Vocabulary</Typography1>
                </AccordionSummary>
                <Stack direction="column" spacing={3}>
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography1>Add Sub Categories </Typography1>
                    </AccordionSummary>
                    {/* sub category */}
                    <SubcategoryForm type="Vocab" mainCategory={"Vocabulary"} />
                  </Accordion>
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography1>Upload Vocab Categories Content</Typography1>
                    </AccordionSummary>
                    {/* upload content for words , the content will be same just categories will be chosen from form content */}
                    <VocabForm />
                  </Accordion>

                  {/* <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography1>Numbers</Typography1>
                  </AccordionSummary> */}
                  {/* <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography1>Birds</Typography1>
                  </AccordionSummary> */}
                  {/* <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography1>Animals</Typography1>
                  </AccordionSummary> */}
                  {/* <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography1>Flower</Typography1>
                  </AccordionSummary> */}
                  {/* <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography1>Flower & Plant Parts</Typography1>
                  </AccordionSummary> */}
                  {/* <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography1>Vegetables</Typography1>
                  </AccordionSummary> */}
                  {/* <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography1>Grains</Typography1>
                  </AccordionSummary> */}

                  {/* <Firstdiv>
      <Label for="avatar">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="currentColor"
          class="bi bi-cloud-arrow-up-fill"
          viewBox="0 0 20 10"
        >
          <path d="M8 2a5.53 5.53 0 0 0-3.594 1.342c-.766.66-1.321 1.52-1.464 2.383C1.266 6.095 0 7.555 0 9.318 0 11.366 1.708 13 3.781 13h8.906C14.502 13 16 11.57 16 9.773c0-1.636-1.242-2.969-2.834-3.194C12.923 3.999 10.69 2 8 2zm2.354 5.146a.5.5 0 0 1-.708.708L8.5 6.707V10.5a.5.5 0 0 1-1 0V6.707L6.354 7.854a.5.5 0 1 1-.708-.708l2-2a.5.5 0 0 1 .708 0l2 2z" />
        </svg>
        Upload image-1
      </Label>

      <input
        type="file"
        style={{ display: "none" }}
        id="avatar"
        name="avatar"
        accept="image/png, image/jpeg"
      />

      <IconButton
        color="primary"
        aria-Label="upload picture"
        component="Label"
      >
        <input hidden accept="image/*" type="file" />
      </IconButton>
    </Firstdiv>
    <Firstdiv>
      <Label for="avatar">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="currentColor"
          class="bi bi-cloud-arrow-up-fill"
          viewBox="0 0 20 10"
        >
          <path d="M8 2a5.53 5.53 0 0 0-3.594 1.342c-.766.66-1.321 1.52-1.464 2.383C1.266 6.095 0 7.555 0 9.318 0 11.366 1.708 13 3.781 13h8.906C14.502 13 16 11.57 16 9.773c0-1.636-1.242-2.969-2.834-3.194C12.923 3.999 10.69 2 8 2zm2.354 5.146a.5.5 0 0 1-.708.708L8.5 6.707V10.5a.5.5 0 0 1-1 0V6.707L6.354 7.854a.5.5 0 1 1-.708-.708l2-2a.5.5 0 0 1 .708 0l2 2z" />
        </svg>
        Upload image-2
      </Label>

      <input
        type="file"
        style={{ display: "none" }}
        id="avatar"
        name="avatar"
        accept="image/png, image/jpeg"
      />

      <IconButton
        color="primary"
        aria-Label="upload picture"
        component="Label"
      >
        <input hidden accept="image/*" type="file" />
      </IconButton>
    </Firstdiv>
    <Firstdiv>
      <Label for="avatar">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="currentColor"
          class="bi bi-mic-fill"
          viewBox="2 0 20 10"
        >
          <path d="M5 3a3 3 0 0 1 6 0v5a3 3 0 0 1-6 0V3z" />
          <path d="M3.5 6.5A.5.5 0 0 1 4 7v1a4 4 0 0 0 8 0V7a.5.5 0 0 1 1 0v1a5 5 0 0 1-4.5 4.975V15h3a.5.5 0 0 1 0 1h-7a.5.5 0 0 1 0-1h3v-2.025A5 5 0 0 1 3 8V7a.5.5 0 0 1 .5-.5z" />
        </svg>
        Upload audio
      </Label>

      <input
        type="file"
        style={{ display: "none" }}
        id="avatar"
        name="avatar"
        accept="image/png, image/jpeg"
      />

      <IconButton
        color="primary"
        aria-label="upload picture"
        component="label"
      >
        <input hidden accept="image/*" type="file" />
      </IconButton>
    </Firstdiv> */}
                </Stack>

                {/* <AccordionDetails>
    <Fulldiv>
      <Firstdiv style={{ width: "40%" }}>
        <InputLabel>Description</InputLabel>
        <Input required placeholder="Enter Description" type="text" />
      </Firstdiv>

      <Seconddiv style={{ width: "40%", marginLeft: "6rem" }}>
        <InputLabel>Levels</InputLabel>
        <FormControl sx={{ width: 400 }}>
          <Select
            id="demo-radio-buttons-group-label"
            multiple
            control={<Radio />}
            placeholder="Description"
            value={personName}
            onChange={handleChange}
            input={<OutlinedInput />}
            renderValue={(selected) => selected.join(", ")}
            MenuProps={MenuProps}
            style={{
              height: "35px",
              background: "#ffffff",
              border: "1px solid #e1e1e1",
              boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.14)",
              borderRadius: "3px",
              "@media (min-width: 1024px) and (max-width: 1281px)": {
                //   border: "1px solid red",   
                height: "20vmax",
                fontSize: "2rem",
              },
            }}
          >
            {names.map((name) => (
              <MenuItem key={name} value={name}>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  defaultValue="female"
                  name="radio-buttons-group"
                ></RadioGroup>
                <FormControlLabel
                  control={<Radio />}
                  aria-labelledby="demo-radio-buttons-group-label"
                  name="radio-buttons-group"
                  checked={personName.indexOf(name) > -1}
                  style={{ marginRight: "-1px" }}
                />

                <ListItemText primary={name} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Seconddiv>
    </Fulldiv>
    <center>
      <Button>Upload</Button>
    </center>
  </AccordionDetails> */}
              </Accordion>
              {/* numbers part */}
              <Accordion className="mt-10 ">
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography1>Numbers</Typography1>
                </AccordionSummary>
                <Stack direction="column" spacing={3}>
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography1>Add Sub Categories </Typography1>
                    </AccordionSummary>
                    {/* sub category */}
                    <SubcategoryForm type="Numbers" mainCategory={"Numbers"} />
                  </Accordion>
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography1>
                        Upload Numbers Categories Content
                      </Typography1>
                    </AccordionSummary>
                    {/* upload content for numbers , the content will be same just categories will be chosen from form content */}
                    <NumbersForm type="NumbersForm" />
                  </Accordion>
                </Stack>
              </Accordion>
              {/* video part */}
              <Accordion className="mt-10 ">
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography1>Video</Typography1>
                </AccordionSummary>
                <Stack direction="column" spacing={3}>
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography1>Add Sub Categories </Typography1>
                    </AccordionSummary>
                    {/* sub category */}
                    <SubcategoryForm type="Video" mainCategory={"Video"} />
                  </Accordion>
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography1>Upload Video Categories Content</Typography1>
                    </AccordionSummary>
                    {/* upload content for video , the content will be same just categories will be chosen from form content */}
                    <VideoForm type="VideoForm" />
                  </Accordion>
                </Stack>
              </Accordion>
            </div>
          </Maindiv>
        </div>
      ) : (
        <div>
          <Sidebar />
          <Maindiv>
            <SearchProfile>
              <Profile src={profileimage} onClick={openLogin} />
              {/* <Bell onClick={openNotification} src={bell} /> */}
              <Heading>Play</Heading>
            </SearchProfile>
            <div className="ml-10 mt-10">
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2a-content"
                  id="panel2a-header"
                >
                  <Typography1>Words</Typography1>
                </AccordionSummary>
                {/* word level 1  */}
                <WordLevel1 />

                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography1>Level-2</Typography1>
                  </AccordionSummary>
                  {/* level2 form here */}
                  <WordLevel2 />
                </Accordion>

                {/* word level3 here */}
                <WordLevel3 />
                {/* end accordion */}
              </Accordion>

              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2a-content"
                  id="panel2a-header"
                >
                  <Typography1>Numbers</Typography1>
                </AccordionSummary>
                <NumLevel1 type={"Num"} />
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography1>Level-2</Typography1>
                  </AccordionSummary>
                  {/* level2 form here */}
                  <NumLevel2 />
                </Accordion>

                {/* level3 form here */}
                <NumLevel3 />
              </Accordion>

              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2a-content"
                  id="panel2a-header"
                >
                  <Typography1>Vocabulary</Typography1>
                </AccordionSummary>
                <VocabLevel1 type={"Vocab"} />

                {/* level2 form here */}
                <VocabLevel2 />
              </Accordion>
            </div>
          </Maindiv>
        </div>
      )}
    </>
  );
};

export default Learn;
