import { create } from "zustand";
import axios from "axios";
import { baseURL } from "./api";
export const useUserStore = create((set) => ({
  usersLength: 0,
  setUsersLength: async () => {
    const { data } = await axios.get(`${baseURL}/admin/get/all/users`);
    set({ usersLength: data?.allUsers?.length });
  },
}));

export const useWordsStore = create((set) => ({
  words: [],
  fetchWords: async () => {
    const { data } = await axios.get(`${baseURL}/learn/fetch/words`);
    set({ words: data?.words });
  },
  deleteAllWords: async () => {
    await axios.get(`${baseURL}/learn/delete/all/words`);
  },
  deleteSingleWord: async (id) => {
    await axios.get(`${baseURL}/learn/delete/word/${id}`);
  },
}));
