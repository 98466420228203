import React, { useReducer } from "react";
import {
  Typography1,
  Label,
  Input,
  InputO1,
  InputLabel,
  Fulldiv,
  Firstdiv,
  Button,
} from "../../Components/DesignComponents";
import { baseURL } from "../../utils/api";
import { Accordion, AccordionSummary, IconButton, Stack } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import axios from "axios";
import { useForm } from "react-hook-form";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AccordionDetails from "@mui/material/AccordionDetails";
import FormLoader from "../../Components/FormLoader";

const VocabLevel2 = () => {
  const { handleSubmit, formState } = useForm();
  const { isSubmitting } = formState;

  function reducer(state, action) {
    const { payload } = action;
    if (action.type === "INPUT_CHANGE") {
      return {
        ...state,
        [payload.field]: payload.value,
      };
    } else if (action.type === "FILE_CHANGE") {
      return {
        ...state,
        image: action.payload,
      };
    }
    return state;
  }
  const initialState = {
    hindiTitle: "",
    englishTitle: "",
    teluguTitle: "",
    tamilTitle: "",
    optionA: "",
    optionB: "",
    optionC: "",
    optionD: "",
    correctOption: "",
    image: "",
  };
  const [state, dispatch] = useReducer(reducer, initialState);

  const handleChangeInputs = (e) => {
    const field = e.target.name;
    const value = e.target.value;
    dispatch({
      type: "INPUT_CHANGE",
      payload: {
        field,
        value,
      },
    });
  };

  const submitForm = async () => {
    const formData = new FormData();

    formData.append("text", state.englishTitle);
    formData.append("optionA", state.optionA);
    formData.append("optionB", state.optionB);
    formData.append("optionC", state.optionC);
    formData.append("optionD", state.optionD);
    formData.append("correctOption", state.correctOption);
    try {
      const { data } = await axios.post(
        `${baseURL}/play/upload/vocab/level2`,
        formData,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      toast("Level added");
    } catch (error) {
      console.log(error);
      toast.error("Something went wrong");
    }
  };
  const open = true;
  return (
    <>
      <form onSubmit={handleSubmit(submitForm)}>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography1>Level-2</Typography1>
          </AccordionSummary>

          <Stack direction="row" alignItems="center" spacing={2}></Stack>
          <AccordionDetails>
            <AccordionDetails>
              <div className="grid grid-cols-4 gap-4 ">
                <div>
                  <InputLabel>Title</InputLabel>
                  <Input
                    name="englishTitle"
                    value={state.englishTitle}
                    onChange={handleChangeInputs}
                    required
                    placeholder="Enter title (English)"
                    type="text"
                  />
                </div>
              </div>

              <div className="grid grid-cols-4 mt-8">
                <div>
                  <InputLabel>Option-1</InputLabel>
                  <InputO1
                    name="optionA"
                    value={state.optionA}
                    onChange={handleChangeInputs}
                    required
                    placeholder="Option-1"
                    type="text"
                  />
                </div>

                <div>
                  <InputLabel>Option-2</InputLabel>
                  <InputO1
                    name="optionB"
                    value={state.optionB}
                    onChange={handleChangeInputs}
                    required
                    placeholder="Option-2"
                    type="text"
                  />
                </div>
                <div>
                  <InputLabel>Option-3</InputLabel>
                  <InputO1
                    name="optionC"
                    value={state.optionC}
                    onChange={handleChangeInputs}
                    required
                    placeholder="Option-3"
                    type="text"
                  />
                </div>
                <div>
                  <InputLabel>Option-4</InputLabel>
                  <InputO1
                    name="optionD"
                    value={state.optionD}
                    onChange={handleChangeInputs}
                    required
                    placeholder="Option-4"
                    type="text"
                  />
                </div>
              </div>

              <Fulldiv
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginTop: "2rem",
                }}
              >
                <Firstdiv>
                  <InputLabel>Correct Option</InputLabel>
                  <Input
                    name="correctOption"
                    value={state.correctOption}
                    onChange={handleChangeInputs}
                    required
                    placeholder="ABC"
                    type="text"
                  />
                </Firstdiv>
              </Fulldiv>
              <center>
                <Button disabled={isSubmitting}>Upload</Button>
              </center>
              {isSubmitting && <FormLoader open={open} />}
              <ToastContainer
                position="top-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                success
                theme="dark"
              />
            </AccordionDetails>
          </AccordionDetails>
        </Accordion>
      </form>
    </>
  );
};

export default VocabLevel2;
