import React from "react";
import { useNavigate } from "react-router-dom";
import Sidebar from "./Sidebar";
import bell from "../assets/bell.png";
import profileimage from "../assets/profileimage.png";
import styled from "styled-components";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import OutlinedInput from "@mui/material/OutlinedInput";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { Stack } from "@mui/material";
import IconButton from "@mui/material/IconButton";
// import { makeStyles } from '@material-ui/core/styles';
import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";
import FormControlLabel from "@mui/material/FormControlLabel";

const ITEM_HEIGHT = 100;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.2 + ITEM_PADDING_TOP,
      width: 200,
    },
  },
};
const Maindiv = styled.div`
  display: flex;
  flex-direction: column;
  width: 75%;
  margin-left: 20rem;
  margin-top: -50vmax;
  @media (min-width: 1024px) and (max-width: 1281px) {
    font-size: 10px;

    width: 70%;
  }
`;
const SearchProfile = styled.div`
  margin-left: 0rem;
  margin-bottom: 3rem;
  margin-top: -8rem;

  width: 99%;
  height: 3rem;
  @media (min-width: 1024px) and (max-width: 1281px) {
    margin-left: 4rem;
  }
`;
const Profile = styled.img`
  float: right;
  margin-right: 1rem;
  cursor: pointer;
  margin-top: -2.6rem;
  @media (min-width: 1024px) and (max-width: 1281px) {
    margin-right: 3rem;
  }
`;
const Bell = styled.img`
  float: right;
  cursor: pointer;
  display: flex;
  margin-right: 5.5rem;
  margin-top: -2.2rem;
`;

const InputLabel = styled.label`
  padding: 7px;
  font-weight: 600;
  width: 14rem;
  ${"" /* margin:2rem; */}
  ${"" /* margin-left:10rem; */}
  text-align:left;
  font-size: 20px;
  color: #000000;
  display: flex;

  ::placeholder {
    font-family: "Source Sans Pro";
    font-size: 15px;
  }
  @media (min-width: 1024px) and (max-width: 1281px) {
    width: 50%;
    font-size: 2rem;
    font-weight: 500;
    margin-bottom: 2rem;
  }
`;
const Input = styled.input`
  /* border-bottom: 0; */
  ${"" /* padding-bottom: 5px; */}
  ${"" /* height:2rem; */}
    width: 15vmax;
  height: 2rem;
  font-weight: 400;
  padding: 7px;
  background: #ffffff;
  border: 1px solid #e1e1e1;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.14);
  border-radius: 3px;
  ::placeholder {
    font-family: "Source Sans Pro";
    color: #848484;
    font-size: 15px;
  }
  @media (min-width: 1024px) and (max-width: 1281px) {
    width: 115%;
    height: 2rem;
    ::placeholder {
      font-family: "Source Sans Pro";
      color: #787878;
      font-size: 20px;
    }
  }
`;

const TextArea = styled.textarea`
  width: 26vmax;
  background: #ffffff;
  border: 1px solid #e1e1e1;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.14);
  border-radius: 2px;
  ::placeholder {
    font-family: "Source Sans Pro";
    color: #787878;
    font-size: 20px;
  }
  @media (min-width: 1024px) and (max-width: 1281px) {
    font-size: 2px;
    height: 2rem;
    margin-top: 2rem;
    ::placeholder {
      font-family: "Source Sans Pro";
      color: #787878;
      font-size: 25px;
    }
  }
`;

const Button = styled.button`
  background: #e07f50;
  border-radius: 40px;
  border: none;
  outline: none;
  font-family: "Source Sans Pro";
  cursor: pointer;
  margin-top: 1rem;
  margin-left: 60rem;
  /* identical to box height */
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  padding: 0.8rem 3rem;
  color: #ffffff;

  /* Inside auto layout */
  @media (min-width: 1024px) and (max-width: 1281px) {
    ${"" /* margin-left:1px; */}
    margin-left: 40rem;
  }
`;

const Typography1 = styled.label`
  font-size: 1.5rem;
  font-weight: 500;
  @media (min-width: 1024px) and (max-width: 1281px) {
    font-size: 2rem;
  }
`;
const Button2 = styled.button`
  background: #9dc6fe;
  height: 2.5rem;
  width: 11rem;
  border: none;
  margin-left: 1rem;
  ${
    "" /* ::label {
      font-family: "Source Sans Pro";
      color: #787878;
      font-size: 4rem;
    } */
  }
`;
const Label = styled.label`
  background: #9dc6fe;
  box-sizing: border-box;
  border: 1px solid #e1e1e1;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.14);
  border-radius: 2px;
  margin-left: 4rem;
  font-family: Source Sans Pro;
  color: #787878;
  font-size: 15px;
  color: #063c85;
  width: 13rem;
  display: inherit;
  font-weight: 600;
  text-align: center;
  padding: 9px;
`;

const Fulldiv = styled.div`
  display: flex;
  flexdirection: row;
`;
const Firstdiv = styled.div`
  width: 40%;
`;

const Seconddiv = styled.div`
  width: 40%;
`;
const Inputdiv = styled.input`
  width: 85%;
  margin-top: 5px;
  height: 34px;
  margin-left: 2rem;
  border: none;
  @media (min-width: 1024px) and (max-width: 1281px) {
    margin-left: -1rem;
    width: 82%;
  }
`;
const Heading = styled.h1`
  margin-left: 2rem;
  @media (min-width: 1024px) and (max-width: 1281px) {
    margin-left: -1rem;
    width: 30%;
    font-size: 2rem;
  }
`;

const Searchdiv = styled.div`
  display: flex;

  @media (min-width: 1024px) and (max-width: 1281px) {
    margin-left: -4rem;
    margin-right: 2rem;
  }
`;
const Inputs = styled.input`
  width: 80%;
  margin-top: 10px;
  height: 34px;
  margin-left: 1rem;
  border: none;

  ::placeholder {
    font-family: "Source Sans Pro";
    color: #848484;
    font-size: 15px;
  }
  @media (min-width: 1024px) and (max-width: 1281px) {
    width: 73%;
    height: 2rem;
    ::placeholder {
      font-family: "Source Sans Pro";
      color: #787878;
      font-size: 20px;
    }
  }
`;

const Btndiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 90px;
  ${"" /* border: 1px solid red; */}
  margin-left: 14rem;
  margin-top: 6rem;
  width: 45vw;
  height: 13vh;
  padding: 1rem;
`;

const Firstbluediv = styled.button`
  box-sizing: border-box;
  background: #daeaff;
  border: 1px solid #9dc6fe;
  border-radius: 7px;
  width: 20vw;
  cursor: pointer;
`;
const Heading1 = styled.h5`
  font-weight: 400;
  font-size: 22px;
  text-align: left;
  margin-left: 2rem;
  color: #000000;
`;

const Content = () => {
  const navigate = useNavigate();
  const [searchInput, setSearchInput] = React.useState("");
  const [personName, setPersonName] = React.useState([]);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setPersonName(typeof value === "string" ? value.split(",") : value);
  };
  const openLogin = () => {
    navigate("/profile");
  };
  const openNotification = () => {
    navigate("/notification");
  };

  const names = [
    "Level-1",
    "Level-2",
    "Level-3",
    "Level-4",
    "Level-5",
    "Level-6",
    "Level-7",
    "Level-8",
    "Level-9",
    "Level-10",
  ];

  const categories = ["Stories", "Paragraphs", "Rhymes", "Phonics"];
  return (
    <>
      <Sidebar />
      <Maindiv>
        <SearchProfile>
        
          {/* <Profile src={profileimage} onClick={openLogin} /> */}
          {/* <Bell onClick={openNotification} src={bell} /> */}
          <Heading>Courses</Heading>
        </SearchProfile>
        <Btndiv>
          <Firstbluediv
            onClick={() => {
              navigate("/content/learn", { state: { xyz: "Learn" } });
            }}
          >
            <Heading1>Learn</Heading1>
          </Firstbluediv>

          <Firstbluediv
            onClick={() => {
              navigate("/content/learn", { state: { xyz: "Play" } });
            }}
          >
            <Heading1>Play</Heading1>
          </Firstbluediv>
        </Btndiv>
      </Maindiv>
    </>
  );
};
export default Content;
