import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Certificate from "./Components/Certificate";
import Content from "./Components/Content";
import HelpSupport from "./Components/HelpSupport";
import Sidebar from "./Components/Sidebar";
import UsermanageDashboard from "./Components/UsermanageDashboard";
import Users from "./Components/Users";
import Chngepswrd from "./Pages/Chngepswrd";
import Forgotpassword from "./Pages/ForgotPassword";
import Login from "./Pages/Login";
import SignUp from "./Pages/SignUp";
import Notify from "./Components/Notify";
import Profilepage from "./Pages/Profilepage";
import Blogs from "./Pages/Blogs";
import Learn from "./Components/Learn";
import Subcategories from "./Pages/Subcategories";
import AllContent from "./Pages/AllContent";
import AllLevels from "./Pages/AllLevels";
import {useNavigate} from "react-router-dom";
import { GetPdf } from "./Components/ViewPdf";
const App = () => {
  const location = useLocation();
  const navigate = useNavigate()
  const [user, setUser] = useState(false);
  useEffect(() => {
    const admin = JSON.parse(localStorage.getItem("elearnadmin"));
    if (!admin) {
      navigate("/login")
    }
  }, [user, location.pathname]);

  return (
    <>
      {/* {user && <Sidebar/>} */}
      <Routes>
        <Route path="/" exact element={<UsermanageDashboard />} />
        <Route path="/login" element={<Login />} />
        <Route path="/users" exact element={<Users />} />
        <Route path="/certificate" exact element={<Certificate />} />
        <Route path="/signup" element={<SignUp />} />
        <Route path="/login" element={<Login />} />
        <Route path="/forgot" element={<Forgotpassword />} />
        <Route path="/chngepswrd" element={<Chngepswrd />} />
        <Route path="/content" element={<Content />} />
        <Route path="/helpsupport" element={<HelpSupport />} />
        <Route path="/notification" element={<Notify />} />
        <Route path="/profile" element={<Profilepage />} />
        <Route path="/content/learn" element={<Learn />} />
        <Route path="blogs" element={<Blogs />} />
        <Route path="/view/pdf" element={<GetPdf />} />
        <Route path="/categories" element={<Subcategories />} />
        <Route path="/all_content" element={<AllContent />} />
        <Route path="/all_levels" element={<AllLevels />} />
      </Routes>
    </>
  );
};
export default App;
