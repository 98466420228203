import React from "react";
import styled from "styled-components";
import chngepswrd from "../assets/Chngepswrd.png";
import { Form } from "react-bootstrap";
import { BsEye, BsEyeSlash } from "react-icons/bs";
import frametwo from "../assets/frameTwo.png";

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0% 30% 0% 0%;
  gap: 5vw;
  background-color: #9dc6fe;
  /* background: red; */
  /* margin-top:30px; */
  align-items: center;
  /* position: fixed; */
  @media (min-width: 993px) and (max-width: 1024px) {
    width: 100%;
    display: fit-content;
  }
  @media (min-width: 1025px) and (max-width: 1285px) {
    width: 100%;
    display: fit-content;
  }
  @media (min-width: 1286px) and (max-width: 1367px) {
    width: 100%;
    height: 100%;
    display: fit-content;
  }
`;

const SignupImgDiv = styled.div`

`;
const SginupImg = styled.img`
  /* height: 40vw; */
  height: 97.4vh;
  width: 44.5vw;
  @media (min-width: 993px) and (max-width: 1024px) {
    width: 100%;
    height: 100%;
  }
  @media (min-width: 1025px) and (max-width: 1285px) {
    width: 100%;
    height: 100%;
  }
  @media (min-width: 1286px) and (max-width: 1367px) {
    width: 100%;
    height: 100%;
  }
`;
const FomrmWrap = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1px;
  margin-top: -11rem;
  @media (min-width: 993px) and (max-width: 1024px) {
    width: 100%;
    height: 100%;
  }
  @media (min-width: 1025px) and (max-width: 1285px) {
    width: 100%;
    height: 100%;
  }
  @media (min-width: 1286px) and (max-width: 1367px) {
    width: 100%;
    height: 100%;
  }
`;
const ToLearn = styled.p`
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  color: #124e9f;
  margin-top: 15px;
  letter-spacing: 2px;
  @media (min-width: 993px) and (max-width: 1024px) {
    width: 100%;
    height: 100%;
  }
  @media (min-width: 1025px) and (max-width: 1285px) {
    width: 100%;
    height: 100%;
  }
  @media (min-width: 1286px) and (max-width: 1367px) {
    width: 100%;
    height: 100%;
  }
`;
const InputWrapper = styled.div`
  margin-top: 2vmax;
  display: flex;
  flex-direction: column;
  gap: 20px;
  @media (min-width: 993px) and (max-width: 1024px) {
    width: 10%;
    height:100%;
  }
  @media (min-width: 1025px) and (max-width: 1285px) {
    width: 10%;  
    height: 100%;
  }
  @media (min-width: 1286px) and (max-width: 1367px) {
    width: 10%;
    height: 100%;
  }
`;
const InputLabel = styled.label`
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  margin-top: 2vmax;
  @media (min-width: 993px) and (max-width: 1024px) {
    width: 10%;
    height: 100%;
  }
  @media (min-width: 1025px) and (max-width: 1285px) {
    width: 10%;
    height: 100%;
  }
  @media (min-width: 1286px) and (max-width: 1367px) {
    width: 10%;
    height: 100%;
  }
`;

const Input = styled.input`
  border: none;
  border-bottom: 1px solid black;
  outline: none;
  /* border-bottom: 0; */
  padding-bottom: 5px;
  background: transparent;
  width: 30vmax;
  padding-top: 10px;
  font-weight: 400;
  ::placeholder {
    color: #787878;
    font-size: 17px;
  }
`;
const Button = styled.button`
  color: #ffffff;
  background: #e07f50;
  border-radius: 40px;
  width: 27vmax;
  border: none;
  outline: none;
  height: 3.5vmax;
  cursor: pointer;
  font-weight: bold;
  font-size: 20px;
  margin-top: 30px;
  &:focus {
    outline: 1px solid black;
  }
`;

const Icon = styled.img`
  margin-left: -8rem;
  height: 68px;
  margin-top: 25rem;
  position: absolute;
  z-index: 1;
`;

const Chngepswrd = () => {
  const [password, setPassword] = React.useState("");
  const [showPassword, setShowPassword] = React.useState(false);
  return (
    <>
      <Wrapper>
        <SignupImgDiv>
          <SginupImg src={chngepswrd} />
          <Icon src={frametwo} />
        </SignupImgDiv>
        <FomrmWrap>
          <ToLearn></ToLearn>
          <InputWrapper>
            <InputLabel>New Password</InputLabel>
            <Input required placeholder="Enter Password" type="password" />
          </InputWrapper>
          <InputWrapper>
            <InputLabel> Change Password</InputLabel>

            <div style={{ display: "flex" }}>
              <Input
              placeholder="Enter Password"
                type={showPassword ? "text" : "password"}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <div
                style={{ marginLeft: "-2rem" }}
                className="password-toggle"
                onClick={() => setShowPassword(!showPassword)}
              >
                {showPassword ? <BsEyeSlash /> : <BsEye />}
              </div>
            </div>
          </InputWrapper>
          <center>
            <Button type="submit">Change Password</Button>
          </center>
        </FomrmWrap>
      </Wrapper>
    </>
  );
};
export default Chngepswrd;
