import React, { useReducer } from "react";
import { Label, Input, Button } from "../../Components/DesignComponents";
import axios from "axios";
import { useForm } from "react-hook-form";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { baseURL } from "../../utils/api";
import FormLoader from "../../Components/FormLoader";

const WordLevel2 = () => {
  const { handleSubmit, formState } = useForm();
  const { isSubmitting } = formState;

  function reducer(state, action) {
    const { payload } = action;
    if (action.type === "FILE_CHANGE") {
      return {
        ...state,
        [payload.field]: payload.value,
      };
    } else if (action.type === "TEXT_CHANGE") {
      return {
        ...state,
        [payload.field]: payload.value,
      };
    }
    return state;
  }
  const initialState = {
    image1: "",
    image2: "",
    image3: "",
    image4: "",
    image5: "",
    image6: "",
    image7: "",
    image8: "",
    text1: "",
    text2: "",
    text3: "",
    text4: "",
    text5: "",
    text6: "",
    text7: "",
    text8: "",
    id1: "",
    id2: "",
    id3: "",
    id4: "",
    id5: "",
    id6: "",
    id7: "",
    id8: "",
  };

  const [state, dispatch] = useReducer(reducer, initialState);

  const handleFileChange = (e) => {
    const field = e.target.name;
    const value = e.target.files[0];
    dispatch({
      type: "FILE_CHANGE",
      payload: {
        field,
        value,
      },
    });
  };
  const handleInputChange = (e) => {
    const field = e.target.name;
    const value = e.target.value;
    dispatch({
      type: "TEXT_CHANGE",
      payload: {
        field,
        value,
      },
    });
  };

  // create array of objects for texts->

  const texts = [
    {
      id: state.id1,
      name: state.text1,
      src: null,
      accept: ["image"],
    },
    {
      id: state.id2,
      name: state.text2,
      src: null,
      accept: ["image"],
    },
    {
      id: state.id3,
      name: state.text3,
      src: null,
      accept: ["image"],
    },
    {
      id: state.id4,
      name: state.text4,
      src: null,
      accept: ["image"],
    },
    {
      id: state.id5,
      name: state.text5,
      src: null,
      accept: ["image"],
    },
    {
      id: state.id6,
      name: state.text6,
      src: null,
      accept: ["image"],
    },
    {
      id: state.id7,
      name: state.text7,
      src: null,
      accept: ["image"],
    },
    {
      id: state.id8,
      name: state.text8,
      src: null,
      accept: ["image"],
    },
  ];

  const submitForm = async () => {
    const formData = new FormData();
    formData.append("image1", state.image1);
    formData.append("image2", state.image2);
    formData.append("image3", state.image3);
    formData.append("image4", state.image4);
    formData.append("image5", state.image5);
    formData.append("image6", state.image6);
    formData.append("image7", state.image7);
    formData.append("image8", state.image8);
    formData.append("texts", JSON.stringify(texts));
    try {
      const data = await axios.post(
        `${baseURL}/play/upload/word/level2`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      console.log(data);
      toast("Level added");
    } catch (error) {
      console.log(error);
      toast.error("Something went wrong");
    }
  };

  const open = true;

  return (
    <>
      <div className=" flex justify-center items-center">
        <form onSubmit={handleSubmit(submitForm)}>
          <div className="grid grid-cols-4 gap-4 p-1">
            <div className="flex flex-col gap-2 justify-center items-center">
              <Label for="img_1_level2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  class="bi bi-cloud-arrow-up-fill"
                  viewBox="0 0 20 10"
                >
                  <path d="M8 2a5.53 5.53 0 0 0-3.594 1.342c-.766.66-1.321 1.52-1.464 2.383C1.266 6.095 0 7.555 0 9.318 0 11.366 1.708 13 3.781 13h8.906C14.502 13 16 11.57 16 9.773c0-1.636-1.242-2.969-2.834-3.194C12.923 3.999 10.69 2 8 2zm2.354 5.146a.5.5 0 0 1-.708.708L8.5 6.707V10.5a.5.5 0 0 1-1 0V6.707L6.354 7.854a.5.5 0 1 1-.708-.708l2-2a.5.5 0 0 1 .708 0l2 2z" />
                </svg>
                Upload image-1 id-1
              </Label>
              <span>{state.image1.name} </span>
              <input
                type="file"
                style={{ display: "none" }}
                id="img_1_level2"
                name="image1"
                onChange={handleFileChange}
                accept="image/*"
              />
            </div>
            <div className="flex flex-col gap-2 justify-center items-center">
              <Label for="img_2_level2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  class="bi bi-cloud-arrow-up-fill"
                  viewBox="0 0 20 10"
                >
                  <path d="M8 2a5.53 5.53 0 0 0-3.594 1.342c-.766.66-1.321 1.52-1.464 2.383C1.266 6.095 0 7.555 0 9.318 0 11.366 1.708 13 3.781 13h8.906C14.502 13 16 11.57 16 9.773c0-1.636-1.242-2.969-2.834-3.194C12.923 3.999 10.69 2 8 2zm2.354 5.146a.5.5 0 0 1-.708.708L8.5 6.707V10.5a.5.5 0 0 1-1 0V6.707L6.354 7.854a.5.5 0 1 1-.708-.708l2-2a.5.5 0 0 1 .708 0l2 2z" />
                </svg>
                Upload image-2 id-2
              </Label>
              <span>{state.image2.name}</span>
              <input
                required
                type="file"
                style={{ display: "none" }}
                id="img_2_level2"
                name="image2"
                onChange={handleFileChange}
                accept="image/*"
              />
            </div>
            <div className="flex flex-col gap-2 justify-center items-center">
              <Label for="img_3_level2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  class="bi bi-cloud-arrow-up-fill"
                  viewBox="0 0 20 10"
                >
                  <path d="M8 2a5.53 5.53 0 0 0-3.594 1.342c-.766.66-1.321 1.52-1.464 2.383C1.266 6.095 0 7.555 0 9.318 0 11.366 1.708 13 3.781 13h8.906C14.502 13 16 11.57 16 9.773c0-1.636-1.242-2.969-2.834-3.194C12.923 3.999 10.69 2 8 2zm2.354 5.146a.5.5 0 0 1-.708.708L8.5 6.707V10.5a.5.5 0 0 1-1 0V6.707L6.354 7.854a.5.5 0 1 1-.708-.708l2-2a.5.5 0 0 1 .708 0l2 2z" />
                </svg>
                Upload image-3 id-3
              </Label>
              <span>{state.image3.name}</span>
              <input
                type="file"
                style={{ display: "none" }}
                id="img_3_level2"
                name="image3"
                onChange={handleFileChange}
                accept="image/*"
              />
            </div>
            <div className="flex flex-col gap-2 justify-center items-center">
              <Label for="img_4_level2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  class="bi bi-cloud-arrow-up-fill"
                  viewBox="0 0 20 10"
                >
                  <path d="M8 2a5.53 5.53 0 0 0-3.594 1.342c-.766.66-1.321 1.52-1.464 2.383C1.266 6.095 0 7.555 0 9.318 0 11.366 1.708 13 3.781 13h8.906C14.502 13 16 11.57 16 9.773c0-1.636-1.242-2.969-2.834-3.194C12.923 3.999 10.69 2 8 2zm2.354 5.146a.5.5 0 0 1-.708.708L8.5 6.707V10.5a.5.5 0 0 1-1 0V6.707L6.354 7.854a.5.5 0 1 1-.708-.708l2-2a.5.5 0 0 1 .708 0l2 2z" />
                </svg>
                Upload image-4 id-4
              </Label>
              <span>{state.image4.name} </span>
              <input
                required
                type="file"
                style={{ display: "none" }}
                id="img_4_level2"
                name="image4"
                onChange={handleFileChange}
                accept="image/*"
              />
            </div>
            <div className="flex flex-col gap-2 justify-center items-center">
              <Label for="img_5_level2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  class="bi bi-cloud-arrow-up-fill"
                  viewBox="0 0 20 10"
                >
                  <path d="M8 2a5.53 5.53 0 0 0-3.594 1.342c-.766.66-1.321 1.52-1.464 2.383C1.266 6.095 0 7.555 0 9.318 0 11.366 1.708 13 3.781 13h8.906C14.502 13 16 11.57 16 9.773c0-1.636-1.242-2.969-2.834-3.194C12.923 3.999 10.69 2 8 2zm2.354 5.146a.5.5 0 0 1-.708.708L8.5 6.707V10.5a.5.5 0 0 1-1 0V6.707L6.354 7.854a.5.5 0 1 1-.708-.708l2-2a.5.5 0 0 1 .708 0l2 2z" />
                </svg>
                Upload image-5 id-5
              </Label>
              <span>{state.image5.name} </span>
              <input
                required
                type="file"
                style={{ display: "none" }}
                id="img_5_level2"
                name="image5"
                onChange={handleFileChange}
                accept="image/*"
              />
            </div>
            <div className="flex flex-col gap-2 justify-center items-center">
              <Label for="img_6_level2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  class="bi bi-cloud-arrow-up-fill"
                  viewBox="0 0 20 10"
                >
                  <path d="M8 2a5.53 5.53 0 0 0-3.594 1.342c-.766.66-1.321 1.52-1.464 2.383C1.266 6.095 0 7.555 0 9.318 0 11.366 1.708 13 3.781 13h8.906C14.502 13 16 11.57 16 9.773c0-1.636-1.242-2.969-2.834-3.194C12.923 3.999 10.69 2 8 2zm2.354 5.146a.5.5 0 0 1-.708.708L8.5 6.707V10.5a.5.5 0 0 1-1 0V6.707L6.354 7.854a.5.5 0 1 1-.708-.708l2-2a.5.5 0 0 1 .708 0l2 2z" />
                </svg>
                Upload image-6 id-6
              </Label>
              <span>{state.image6.name} </span>
              <input
                required
                type="file"
                style={{ display: "none" }}
                id="img_6_level2"
                name="image6"
                onChange={handleFileChange}
                accept="image/*"
              />
            </div>
            <div className="flex flex-col gap-2 justify-center items-center">
              <Label for="img_7_level2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  class="bi bi-cloud-arrow-up-fill"
                  viewBox="0 0 20 10"
                >
                  <path d="M8 2a5.53 5.53 0 0 0-3.594 1.342c-.766.66-1.321 1.52-1.464 2.383C1.266 6.095 0 7.555 0 9.318 0 11.366 1.708 13 3.781 13h8.906C14.502 13 16 11.57 16 9.773c0-1.636-1.242-2.969-2.834-3.194C12.923 3.999 10.69 2 8 2zm2.354 5.146a.5.5 0 0 1-.708.708L8.5 6.707V10.5a.5.5 0 0 1-1 0V6.707L6.354 7.854a.5.5 0 1 1-.708-.708l2-2a.5.5 0 0 1 .708 0l2 2z" />
                </svg>
                Upload image-7 id-7
              </Label>
              <span>{state.image7.name}</span>
              <input
                required
                type="file"
                style={{ display: "none" }}
                id="img_7_level2"
                name="image7"
                onChange={handleFileChange}
                accept="image/*"
              />
            </div>
            <div className="flex flex-col gap-2 justify-center items-center">
              <Label for="img_8_level2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  class="bi bi-cloud-arrow-up-fill"
                  viewBox="0 0 20 10"
                >
                  <path d="M8 2a5.53 5.53 0 0 0-3.594 1.342c-.766.66-1.321 1.52-1.464 2.383C1.266 6.095 0 7.555 0 9.318 0 11.366 1.708 13 3.781 13h8.906C14.502 13 16 11.57 16 9.773c0-1.636-1.242-2.969-2.834-3.194C12.923 3.999 10.69 2 8 2zm2.354 5.146a.5.5 0 0 1-.708.708L8.5 6.707V10.5a.5.5 0 0 1-1 0V6.707L6.354 7.854a.5.5 0 1 1-.708-.708l2-2a.5.5 0 0 1 .708 0l2 2z" />
                </svg>
                Upload image-8 id-8
              </Label>
              <span>{state.image8.name} </span>
              <input
                required
                type="file"
                style={{ display: "none" }}
                id="img_8_level2"
                name="image8"
                onChange={handleFileChange}
                accept="image/*"
              />
            </div>
          </div>
          {/* text div */}
          <div className="grid grid-cols-4 gap-4 p-1 mt-4 ml-[6vmax]">
            <Input
              name="text1"
              value={state.text1}
              onChange={handleInputChange}
              required
              placeholder=" text 1"
            />
            <Input
              name="id1"
              value={state.id1}
              onChange={handleInputChange}
              required
              placeholder=" Text id "
            />
            <Input
              name="text2"
              value={state.text2}
              onChange={handleInputChange}
              required
              placeholder="text 2 "
            />
            <Input
              name="id2"
              value={state.id2}
              onChange={handleInputChange}
              required
              placeholder=" Text id"
            />
            <Input
              name="text3"
              value={state.text3}
              onChange={handleInputChange}
              required
              placeholder=" text 3"
            />
            <Input
              name="id3"
              value={state.id3}
              onChange={handleInputChange}
              required
              placeholder=" Text id"
            />
            <Input
              name="text4"
              value={state.text4}
              onChange={handleInputChange}
              required
              placeholder=" text 4"
            />
            <Input
              name="id4"
              value={state.id4}
              onChange={handleInputChange}
              required
              placeholder=" Text id"
            />
            <Input
              name="text5"
              value={state.text5}
              onChange={handleInputChange}
              required
              placeholder=" text 5"
            />
            <Input
              name="id5"
              value={state.id5}
              onChange={handleInputChange}
              required
              placeholder=" Text id"
            />
            <Input
              name="text6"
              value={state.text6}
              onChange={handleInputChange}
              required
              placeholder=" text 6"
            />
            <Input
              name="id6"
              value={state.id6}
              onChange={handleInputChange}
              required
              placeholder=" Text id"
            />
            <Input
              name="text7"
              value={state.text7}
              onChange={handleInputChange}
              required
              placeholder=" text 7"
            />
            <Input
              name="id7"
              value={state.id7}
              onChange={handleInputChange}
              required
              placeholder=" Text id"
            />
            <Input
              name="text8"
              value={state.text8}
              onChange={handleInputChange}
              required
              placeholder=" text 8"
            />
            <Input
              name="id8"
              value={state.id8}
              onChange={handleInputChange}
              required
              placeholder=" Text id"
            />
          </div>
          <div className="flex justify-center">
            <Button disabled={isSubmitting} type="submit">
              Submit
            </Button>
          </div>
          {isSubmitting && <FormLoader open={true} />}
          <ToastContainer
            position="top-center"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            success
            theme="dark"
          />
        </form>
      </div>
    </>
  );
};

export default WordLevel2;
