import React from "react";
import bell from "../assets/bell.png";
import profileimage from "../assets/profileimage.png";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";

const SearchProfile = styled.div`
  margin-bottom: 2rem;
  width: 73%;
  margin-top: -10rem;
  @media (min-width: 1024px) and (max-width: 1281px) {
    margin-left: 4rem;
  }
`;
const Bell = styled.img`
  float: right;
  display: flex;
  margin-right: -7rem;
  margin-top: -35px;
  cursor: pointer;
  @media (min-width: 1024px) and (max-width: 1281px) {
    margin-right: -6rem;
    height: 35px;
  }
`;
const Profile = styled.img`
  float: right;
  margin-right: -11rem;
  margin-top: -42px;
  cursor: pointer;
  @media (min-width: 1024px) and (max-width: 1281px) {
    margin-right: -10rem;
  }
`;
const Searchdiv = styled.div`
  display: flex;
  margin-left: -4rem;
  margin-right: 9rem;
  @media (min-width: 1024px) and (max-width: 1281px) {
    margin-left: -7rem;
    width: 81%;
  }
`;
const Inputs = styled.input`
  width: 87%;
  margin-top: 10px;
  height: 34px;
  border: none;
  margin-left: 10vmax;
  @media (min-width: 1024px) and (max-width: 1281px) {
    width: 90%;
    margin-top: 10px;
    height: 34px;
    margin-left: 10vmax;
    border: none;
  }
`;
const Heading = styled.h2`
  font-size: 2rem;
  margin-left: -3rem;
  @media (min-width: 1024px) and (max-width: 1281px) {
    margin-left: -6rem;
  }
`;

const Navbar = () => {
  const navigate = useNavigate();
  const [searchInput, setSearchInput] = React.useState("");
  const openLogin = () => {
    navigate("/profile");
  };
  const openNotification = () => {
    navigate("/notification");
  };
  return (
    <div>
      <SearchProfile>
        <Searchdiv>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            class="bi bi-search"
            viewBox="0 0 16 16"
            className="absolute ml-36 mt-5 "
          >
            <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
            <path d="M0 0h24v24H0z" fill="none" />
          </svg>
          <Inputs
            className="flex justify-center items-center pl-8"
            // placeholder="Search here..."
            aria-label="Search through site content"
            type="text"
            value={searchInput}
            onChange={(e) => setSearchInput(e.target.value)}
          />
        </Searchdiv>
        <Profile src={profileimage} onClick={openLogin} />
        {/* <Bell onClick={openNotification} src={bell} /> */}
      </SearchProfile>
    </div>
  );
};

export default Navbar;
