import React, { useState, useRef } from "react";
import { useReactToPrint } from "react-to-print";
import { CertificateRef } from "./CertificateTemplate";

const ViewPdf = () => {
  const [userName, setUserName] = useState("");
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  return (
    <>
      <input
        type="text"
        value={userName}
        onChange={(e) => setUserName(e.target.value)}
      />
      <CertificateRef userName={userName} ref={componentRef} />
      <button onClick={handlePrint}>Print this out!</button>
    </>
  );
};

export default ViewPdf;

export const GetPdf = () => {
  return (
    <>
    
      <ViewPdf />
    </>
  );
};
