import useSWR from "swr";
import axios from "axios";

export const useFetch = (url) => {
  const fetcher = (url) => axios.get(url).then((res) => res.data);
  const { data, error, loading } = useSWR(url, fetcher, {
    refreshInterval: 1000,
  });
  return [data, error, loading];
};
